import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'

import useDialog from '@shared/hooks/src/useDialog'
import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { handleError, QK } from '@shared/utils'

import Confirmation from '@components/Dialog/Confirmation'

export function useOnDemand(id, options = {}) {
  return useQuery({
    queryKey: QK.appointments.onDemand.id(id).details,
    queryFn: () => API.appointments.onDemand.id(id).details(),
    ...options,
  })
}

export function useOnDemandAppointmentAccept() {
  const me = useMe()
  const navigate = useNavigate()

  const accept = useMutation({
    mutationFn: (id) => API.appointments.onDemand.id(id).accept(),
    onSuccess: (onDemandLog) => {
      queryClient.setQueryData(QK.appointments.onDemand.id(onDemandLog.id).details, (oldData) => ({ ...oldData, ...onDemandLog }))
      queryClient.invalidateQueries({ queryKey: QK.providers.id(me.provider.id).dashboard.appointments.lists })
      queryClient.invalidateQueries({ queryKey: QK.providers.id(me.provider.id).appointments.lists })
    },
    onError: (error, { id }) => {
      queryClient.invalidateQueries({ queryKey: QK.appointments.onDemand.id(id).details })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: QK.appointments.onDemand.lists })
    },
  })

  const handleAccept = useCallback(
    (id) => {
      return accept
        .mutateAsync(id)
        .then((onDemandLog) => navigate(`/app/visits/${onDemandLog.appointmentId}`))
        .catch((e) => handleError(e, { showResponse: true }))
    },
    [accept, navigate]
  )

  return useDialog({
    component: Confirmation,
    props: ({ item: id, close }) => ({
      title: 'Accept On-Demand Appointment',
      description: `Do you wish to proceed with accepting the appointment? This decision is final.`,
      rejectLabel: 'Dismiss',
      confirmLabel: 'Confirm',
      onReject: () => close(),
      onConfirm: () => handleAccept(id).then(close),
    }),
  })
}
