import { QueryClient, QueryClientProvider as QueryClientProviderBase } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { useReadLocalStorage } from 'usehooks-ts'

import { createIDBPersister, isIDBAvailable } from './IndexedDBPersister'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Remove the refetch because https connection seems to be closed by the time when refetch is happening,
      // and it doesn't establish a new connection instead
      refetchOnWindowFocus: false, // disable refetch on window focus
      retry: false, // no retry if fails
      staleTime: 30 * 1000, // 30 sec after data is considered stale
      gcTime: 1000 * 60 * 60, // 1 hour after data is removed from the cache
    },
  },
})

window.queryClient = queryClient

export default function QueryClientProvider({ children }) {
  const cachePersisted = useReadLocalStorage('cache-persisted')
  const cachePersistedOverride = useReadLocalStorage('cache-persisted-override')
  const IDBAvailable = isIDBAvailable()

  if (IDBAvailable && cachePersistedOverride !== 'not-persisted' && (cachePersistedOverride === 'persisted' || cachePersisted === true)) {
    return (
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: createIDBPersister() }}>
        {children}
      </PersistQueryClientProvider>
    )
  }

  return <QueryClientProviderBase client={queryClient}>{children}</QueryClientProviderBase>
}

const queryClientMocked = new QueryClient({
  // silence react-query errors
  logger: {
    log: console.log,
    warn: console.warn,
    error: () => {},
  },
  defaultOptions: {
    queries: {
      retry: false,
      gcTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})

export function QueryClientProviderMocked({ children }) {
  return <QueryClientProviderBase client={queryClientMocked}>{children}</QueryClientProviderBase>
}
