import { useController, useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { mapRailsTimezoneToJS } from '@shared/utils'

import { PhoneFilledIcon, VideoCameraFilledIcon } from '@icons'

export default function SummarySection({ appointment, patient, encounter }) {
  const form = useFormContext()
  const typeField = useController({ name: 'type' })
  const slot = useWatch({ name: 'timeslot' })

  return (
    <Paper variant="outlined">
      <Stack spacing={1} sx={{ p: 2, minHeight: 400 }}>
        <Typography variant="h4" sx={{ pb: 1 }}>
          Scheduling Summary
        </Typography>
        {encounter && (
          <Property label="Encounter">
            <Stack>
              <Typography>{`${encounter.description}, #${encounter.id}`}</Typography>
              <Typography sx={{ color: 'text.secondary' }}>{dayjs(encounter.startTime).format('L')}</Typography>
            </Stack>
          </Property>
        )}
        <Property label="Patient">
          <Stack>
            <Typography>{patient.fullName}</Typography>
            {slot && (
              <Typography sx={{ color: 'text.secondary' }}>
                {dayjs(slot.timeSlot).tz(mapRailsTimezoneToJS(patient.timezone)).format('L LT z')}
              </Typography>
            )}
          </Stack>
        </Property>
        {slot && (
          <Property label="Provider">
            <Stack>
              <Typography>{slot.provider.fullName}</Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {dayjs(slot.timeSlot).tz(mapRailsTimezoneToJS(slot.provider.timezone)).format('L LT z')}
              </Typography>
            </Stack>
          </Property>
        )}
        <Box sx={{ flex: '1 1 0' }} />
        <ButtonGroup fullWidth sx={{ pt: 2 }}>
          <Button
            variant={typeField.field.value === 'video' ? 'contained' : 'outlined'}
            onClick={() => typeField.field.onChange('video')}
            endIcon={<VideoCameraFilledIcon />}
          >
            Video
          </Button>
          <Button
            variant={typeField.field.value === 'phone' ? 'contained' : 'outlined'}
            onClick={() => typeField.field.onChange('phone')}
            endIcon={<PhoneFilledIcon />}
          >
            Phone
          </Button>
        </ButtonGroup>
        <Button
          variant="contained"
          type="submit"
          disabled={!form.formState.isValid || slot?.current === true}
          loading={form.formState.isLoading}
        >
          {appointment ? 'Reschedule' : 'Schedule'} Appointment
        </Button>
      </Stack>
    </Paper>
  )
}

function Property({ label, children, sx = {} }) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="body2" sx={{ color: 'text.secondary', minWidth: 70 }}>
        {label}
      </Typography>
      {children}
    </Stack>
  )
}
