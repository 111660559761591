import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import Avatar from '@components/Avatar'

import Property from './Property'

export default function CBOProperty({ label, data }) {
  if (!data) return <Property label={label}>—</Property>

  const subtitle = [data.city, data.state].filter(Boolean).join(', ')

  return (
    <Property label={label}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Avatar.CBO card data={data} />
        <Stack spacing={-0.5}>
          <Typography>{data.name}</Typography>
          {subtitle && <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>}
        </Stack>
      </Stack>
    </Property>
  )
}

CBOProperty.Loading = function ({ label }) {
  return (
    <Property label={label}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Skeleton variant="rounded" width={40} height={40} />
        <Stack spacing={-0.5}>
          <Typography>
            <Skeleton width={150} />
          </Typography>
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Stack>
      </Stack>
    </Property>
  )
}
