import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

import Property from '@components/Property'

import { Section } from '../CBORuleSet.utils'

export default function Coverage({ data, isLoading }) {
  return (
    <Section paper title="Coverage">
      {isLoading && <Location.Loading />}
      <Stack spacing={2}>
        {data?.coveredCounties?.map(({ state, counties }) => (
          <Location key={state} state={state} counties={counties || []} />
        ))}
      </Stack>
    </Section>
  )
}

function Location({ state, counties }) {
  return (
    <Stack direction="row" sx={{ gap: 1 }}>
      <Property labelWidth={80} label="State" value={state} />
      <Property labelWidth={80} label="Counties" value={counties.length === 0 ? 'ALL' : counties.join(', ')} />
    </Stack>
  )
}

Location.Loading = function () {
  return (
    <Stack direction="row" sx={{ gap: 1 }}>
      <Property labelWidth={80} label="State" value={<Skeleton width={50} />} />
      <Property labelWidth={80} label="Counties" value={<Skeleton width={200} />} />
    </Stack>
  )
}
