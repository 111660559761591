import { useState } from 'react'

import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@components/_mui/IconButton'

import CBOsSupportMapDialog from '@pages/CBOs/CBOsSupportMapDialog'
import { GlobalIcon } from '@icons'

export default function CBOSupportMap() {
  const [open, setOpen] = useState(false)
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.5 }}>
        <IconButton
          id="cbo-support-button"
          color="secondary"
          onClick={() => setOpen(true)}
          aria-label="CBO support map"
          data-testid="header-cbo-support-map"
          size={matchDownSm ? 'small' : 'medium'}
          sx={{ color: 'text.primary' }}
        >
          <GlobalIcon />
        </IconButton>
      </Box>
      <CBOsSupportMapDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}
