import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { CBORuleSetDetailsDialog } from '@pages/CBOs'
import TableFilters from '@components/TableFilters'
import USAMap from '@components/USAMap'

import useCBOsCoverage, { useFiltersSchema } from './CBOsSupportMapDialog.hooks'
import StateCBOsDetailsDialog from './StateCBOsDetailsDialog'

export default function CBOsSupportMapDialog({ open, onClose }) {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>State Coverage Map</DialogTitle>
      <DialogContent dividers>
        <Content />
      </DialogContent>
    </Dialog>
  )
}

function Content() {
  const theme = useTheme()
  const [filters, updateFilters] = useState({})
  const [state, setState] = useState()
  const [stateCBOsOpen, setStateCBOsOpen] = useState(false)
  const [cboId, setCboId] = useState()
  const [cboRuleSetOpen, setCboRuleSetOpen] = useState(false)

  const schema = useFiltersSchema()
  const { data } = useCBOsCoverage(filters.services)

  return (
    <Stack>
      <TableFilters values={filters} onChange={updateFilters} schema={schema} />
      <USAMap
        data={data || {}}
        defaultFill={theme.palette.error.light}
        onClick={(state) => {
          setState(state)
          setStateCBOsOpen(true)
        }}
        Tooltip={({ abbreviation, ...props }) => (
          <StateTooltip
            state={data?.[abbreviation]}
            onClick={(cboId) => {
              setCboId(cboId)
              setCboRuleSetOpen(true)
            }}
            {...props}
          />
        )}
      />
      <Stack direction="row" sx={{ justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
        <LegendItem color="success.main">Fully covered</LegendItem>
        <LegendItem color="warning.light">Partially covered</LegendItem>
        <LegendItem color="error.light">Not covered</LegendItem>
      </Stack>
      <CBORuleSetDetailsDialog id={cboId} slide open={cboRuleSetOpen} onClose={() => setCboRuleSetOpen(false)} />
      <StateCBOsDetailsDialog state={state} cbos={data?.[state]?.cbos} open={stateCBOsOpen} onClose={() => setStateCBOsOpen(false)} />
    </Stack>
  )
}

function LegendItem({ children, color }) {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: 1,
          backgroundColor: color,
          border: '1px solid',
          borderColor: 'divider',
        }}
      />
      <Typography>{children}</Typography>
    </Stack>
  )
}

const StateTooltip = ({ state, onClick, ...props }) => {
  const hasCBOs = state?.cbos?.length > 0

  const title = hasCBOs
    ? state.cbos.map((cbo) => (
        <Typography key={cbo.id} component="a" onClick={() => onClick(cbo.id)} sx={{ cursor: 'pointer' }}>
          {`${cbo.name}${cbo.isFullyCovered ? '' : ' - Partially Covered'}${cbo.prioritized ? ' - Prioritized' : ''}`}
        </Typography>
      ))
    : undefined

  return <StyledTooltip title={title} {...props} />
}

const StyledTooltip = styled(({ className, children, ...props }) => (
  <Tooltip enterNextDelay={300} leaveDelay={300} {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))({
  '& .MuiTooltip-tooltip': {
    maxWidth: 500,
    whiteSpace: 'pre-wrap',
    '& > .MuiTypography-root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
})
