import { useCallback } from 'react'
import { ToastBar, Toaster as ToasterHot } from 'react-hot-toast'

import useTheme from '@mui/styles/useTheme'

import { ExclamationCircleOutlined } from '@icons'

export default function Toaster() {
  const theme = useTheme()

  // Defines the custom toast styles
  const toastCustomStyle = useCallback(
    (toastType, styles) => {
      // Default
      if (toastType === 'blank') {
        return {
          ...styles,
          ...{
            color: theme.palette.primary.contrastText,
            fontSize: '1rem',
            fontWeight: 700,
            fontFamily: 'proxima-nova',
            background: 'radial-gradient(ellipse at 10% 70%, #008ECF 6%, #00A5F0 100%',
          },
        }
      }

      // Warning
      if (toastType === 'warn') {
        return {
          ...styles,
          ...{
            color: theme.palette.primary.contrastText,
            fontSize: '1rem',
            fontWeight: 700,
            fontFamily: 'proxima-nova',
            background: 'radial-gradient(ellipse at 10% 70%, #FAB001 6%, #D09201 100%',
          },
        }
      }

      // Error
      return {
        ...styles,
        ...{
          color: theme.palette.primary.contrastText,
          fontSize: '1rem',
          fontWeight: 700,
          fontFamily: 'proxima-nova',
          background: 'radial-gradient(ellipse at 10% 70%, #F95301 6%, #D04601 100%',
        },
      }
    },
    [theme]
  )
  const toastCustomIcon = useCallback((toast) => {
    if ('warn' === toast.type && !toast?.icon) {
      toast.icon = <ExclamationCircleOutlined />
    }

    return toast
  }, [])

  return (
    <ToasterHot
      position="top-center"
      toastOptions={{
        duration: 5000,
        error: toastCustomStyle,
        success: {
          style: {
            color: theme.palette.primary.contrastText,
            fontSize: '1rem',
            fontWeight: 700,
            fontFamily: 'proxima-nova',
            background: 'radial-gradient(ellipse at 30% 50%, #04cf00 6%, #02c800 88%',
          },
          iconTheme: {
            primary: 'white',
            secondary: '#04cf00',
          },
        },
      }}
    >
      {(t) => {
        return <ToastBar toast={toastCustomIcon(t)} style={toastCustomStyle(t.type, t.style)} />
      }}
    </ToasterHot>
  )
}
