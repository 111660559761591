import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const Page = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(1),
  overflow: 'hidden',
  gap: theme.spacing(10),
}))

export const Content = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(6),
}))

export const Description = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
}))
