import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@components/_mui/IconButton'

import usePopover from '@shared/hooks/src/usePopover'

import { PagesHistoryPopover } from '@pages/PagesHistory'
import { HistoryOutlinedIcon } from '@icons'

export default function PagesHistory() {
  const popover = usePopover()
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        id="pages-history-button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        color="secondary"
        aria-label="Pages History"
        data-testid="header-pages-history"
        size={matchDownSm ? 'small' : 'medium'}
        sx={{ color: 'text.primary' }}
      >
        <HistoryOutlinedIcon style={{ fontSize: 22 }} />
      </IconButton>
      <PagesHistoryPopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} />
    </Box>
  )
}
