import { useMemo } from 'react'
import dayjs from 'dayjs'

import AvatarMui from '@mui/material/Avatar'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@components/_mui/Typography'

import useIntervalResult from '@shared/hooks/src/useIntervalResult'
import { getTimezoneShift, mapRailsTimezoneToJS } from '@shared/utils'

import { ClockCircleOutlinedIcon } from '@icons'
import { getSizeStyle, mapCBO } from '@components/Avatar/Avatar.utils'

export default function Header({ cbo }) {
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <Avatar data={cbo} />
      <Stack>
        <Typography variant="h4">{cbo.name}</Typography>
        <Time cbo={cbo} />
        {cbo.hoursOfOperation && (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {cbo.hoursOfOperation}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

Header.Loading = function () {
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <Skeleton variant="rounded" width={64} height={64} />
      <Stack>
        <Typography variant="h4">
          <Skeleton width={150} />
        </Typography>
        <Time.Loading />
        <Typography variant="body2">
          <Skeleton width={50} />
        </Typography>
      </Stack>
    </Stack>
  )
}

function Time({ cbo }) {
  const userTimezone = mapRailsTimezoneToJS(cbo.timezone)

  const timezoneDescription = useMemo(() => getTimezoneShift(userTimezone), [userTimezone])

  const time = useIntervalResult(() => {
    try {
      const myTime = dayjs().format('LT')

      if (!userTimezone) return `${myTime} Timezone not set`

      const userTime = dayjs().tz(userTimezone).format('LT')
      const isSame = myTime === userTime

      return `${userTime} ${isSame ? 'same as you' : 'local time'}`
    } catch (e) {
      return '-'
    }
  }, 5000)

  return (
    <Tooltip arrow title={timezoneDescription}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <ClockIcon />
        <Typography>{time}</Typography>
      </Stack>
    </Tooltip>
  )
}

Time.Loading = function () {
  return (
    <Typography>
      <Skeleton width={120} />
    </Typography>
  )
}

const ClockIcon = styled(ClockCircleOutlinedIcon)(({ theme }) => ({ color: theme.palette.text.secondary }))

// Don't use @components/Avatar to prevent circular dependency
function Avatar({ data }) {
  const { backgroundColor, initials } = useMemo(() => mapCBO(data), [data])
  const sx = [getSizeStyle('xl'), { color: 'primary.contrastText', backgroundColor }]

  return (
    <AvatarMui src={data?.logo} variant="rounded" sx={sx}>
      {initials}
    </AvatarMui>
  )
}
