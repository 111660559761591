import { useRef, useState } from 'react'

import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { SettingOutlinedIcon } from '@icons'
import DeviceSelectionDialog from '@components/_twilio/components/DeviceSelectionDialog'
import Transitions from '@components/Transitions'

export default function Settings() {
  const menuButton = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false)

  const theme = useTheme()
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleMenuToggle = () => setMenuOpen((prevOpen) => !prevOpen)
  const handleMenuClose = (event) => {
    if (menuButton.current && menuButton.current.contains(event.target)) return
    setMenuOpen(false)
  }

  const handleListItemClick = (setting) => {
    if (setting === 'avSettings') {
      setDeviceSettingsOpen(true)
    }
    setMenuOpen(false)
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        ref={menuButton}
        color="secondary"
        onClick={handleMenuToggle}
        aria-controls={menuOpen ? 'settings-grow' : undefined}
        aria-label="open settings"
        aria-haspopup="true"
        data-testid="header-settings"
        size={matchDownSm ? 'small' : 'medium'}
        sx={{ color: 'text.primary' }}
      >
        <SettingOutlinedIcon style={{ fontSize: 20 }} />
      </IconButton>

      <Popper
        placement={matchesDownMd ? 'bottom-start' : 'bottom'}
        open={menuOpen}
        anchorEl={menuButton.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesDownMd ? 0 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={menuOpen} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    width: '100%',
                    minWidth: 200,
                    maxWidth: 290,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 0.5,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 250,
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick('avSettings')}>
                    <ListItemText primary={<Typography color="textPrimary">Audio/Video Settings</Typography>} />
                  </ListItemButton>
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <DeviceSelectionDialog open={deviceSettingsOpen} onClose={() => setDeviceSettingsOpen(false)} />
    </Box>
  )
}
