import { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'

import { getTestId } from '@shared/utils'

import { CaretDownOutlinedIcon } from '@icons'

import DateRangeDialog, { DateRangeShortcuts } from '../DateRangeDialog'

DateRangeSelect.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Callback fired when the value changes */
  onChange: PropTypes.func.isRequired,

  /** Dayjs values of range */
  value: PropTypes.array,
}

/**
 * Button with a dropdown menu that allows to select a date range via shortcuts or a custom date range
 *
 * @example
 * <DateRangeSelect label="Dates" value={value} onChange={handleChange} />
 */
export default function DateRangeSelect({ label, onChange, value = [], ...other }) {
  const anchorRef = useRef(null)

  const [openMenu, setOpenMenu] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const testId = getTestId(other, `date-range-select-${label}`)

  return (
    <>
      <Button
        color="inherit"
        endIcon={<DownIcon />}
        ref={anchorRef}
        onClick={() => setOpenMenu(true)}
        data-testid={`${testId}-button`}
        {...other}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        slotProps={{
          paper: {
            'data-testid': `${testId}-paper`,
            style: { width: 250 },
          },
        }}
      >
        <DateRangeShortcuts
          value={value}
          onChange={(value) => {
            setOpenMenu(false)
            onChange(value)
          }}
          data-testid={`${testId}-shortcuts`}
        />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenDialog(true)
            setTimeout(() => setOpenMenu(false), 300)
          }}
          data-testid={`${testId}-custom`}
        >
          Choose Dates...
        </MenuItem>
      </Menu>
      <DateRangeDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        initialValue={value}
        onChange={onChange}
        data-testid={testId}
      />
    </>
  )
}

const DownIcon = styled(CaretDownOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
