import { createContext, useContext } from 'react'

import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { settingsDeserialized } from '@shared/services/src/Settings.mocks'

/**
 * Provide a context for the apps infrastructure settings.
 */
export default function SettingsProvider({ children }) {
  const { data: settings } = useQuery({
    queryKey: ['settings'],
    queryFn: () => API.platform.settings.list(),
    select,
  })

  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>
}

export function SettingsMockedProvider({ children }) {
  return <SettingsContext.Provider value={select(settingsDeserialized)}>{children}</SettingsContext.Provider>
}

export const SettingsContext = createContext(undefined)

export const useSettings = () => useContext(SettingsContext)

/**
 * Return a value for the given setting
 *
 * @example:
 * const someSetting = useSetting(Setting.PhoneNumbersForSms)
 *
 * @param key - The setting key
 * @returns {*} - The setting value
 */
export const useSetting = (key) => {
  const settings = useSettings()
  return settings[key]
}

export const Setting = {
  PhoneNumbersForSms: 'phone_numbers_for_sms',
}

const select = (data) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})
}
