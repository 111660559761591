import axios from 'axios'
import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import { AuthUtils } from '@shared/utils'

class API {
  constructor() {
    this.controller = new AbortController()
    this.api = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      withCredentials: true,
      timeout: 60000,
      headers: {
        Authorization: AuthUtils.getAuthToken(),
        'ngrok-skip-browser-warning': true,
      },
      signal: this.controller.signal,
    })

    this.api.interceptors.request.use((config) => {
      const overrideToken = config.override?.['Authorization']
      if (overrideToken) {
        delete config.override
        config.headers['Authorization'] = overrideToken
      } else {
        config.headers['Authorization'] = AuthUtils.getAuthToken()
      }
      return config
    })
  }

  request(method, url, data = {}, config = {}) {
    const { skipDeserialization = false, forceDeserialization = false, ...restConfig } = config
    const requestConfig = { method, url, ...restConfig }

    if (['get', 'delete'].includes(method)) {
      requestConfig.query = data
    } else {
      requestConfig.data = data
    }

    if (requestConfig.query) {
      requestConfig.url = QS.stringifyUrl({ url: requestConfig.url, query: requestConfig.query }, { arrayFormat: 'bracket' })
      delete requestConfig.query
    }

    return this.api(requestConfig).then((response) => {
      const data = response.data
      const isJSONApi = Boolean(data?.jsonapi?.version)
      const hasData = Boolean(data?.data)
      if (hasData && (forceDeserialization || (!skipDeserialization && isJSONApi))) {
        return deserialize(data, { transformKeys: 'camelCase' })?.data
      }
      return data
    })
  }

  get(url, query = {}, config = {}) {
    return this.request('get', url, query, config)
  }

  post(url, data = {}, config = {}) {
    return this.request('post', url, data, config)
  }

  put(url, data = {}, config = {}) {
    return this.request('put', url, data, config)
  }

  patch(url, data = {}, config = {}) {
    return this.request('patch', url, data, config)
  }

  delete(url, data = {}, config = {}) {
    return this.request('delete', url, data, config)
  }

  abortAndReinitialize(message) {
    this.controller.abort(message)
    this.controller = new AbortController()
    this.api.defaults.signal = this.controller.signal
  }
}

export const Service = new API()

export default Service.api
