import { useMemo } from 'react'
import { Link as RouterLink } from 'react-router'
import dayjs from 'dayjs'

import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { NotesEntity, toTitleCase, userRoleToLabel } from '@shared/utils'

import Avatar from '@components/Avatar'

import { styles } from './TimelineNotes.utils'

/**
 * Displays a note
 * @param {object} data - note serializer data
 * @param {boolean} showSource - whether to show the source of the note
 * @param {boolean} isLast - whether the note is the last in the list
 *
 * @returns {JSX.Element} - note component
 */
export default function Note({ data, showSource = false, isLast = false }) {
  const href = useMemo(() => {
    if (data.entity === NotesEntity.Labs) return `/app/labs/${data.recordId}`
    if (data.entity === NotesEntity.Rx) return `/app/prescriptions/${data.recordId}`
    if (data.entity === NotesEntity.Encounters) return undefined
    if (data.entity === NotesEntity.Appointments) return undefined
    if (data.entity === NotesEntity.Users) return `/app/patients/${data.recordId}`
    return undefined
  }, [data])

  const source = useMemo(() => {
    if (data.entity === NotesEntity.Encounters) return `Encounter ${data.recordId}`
    if (data.entity === NotesEntity.Appointments) return `Appointment ${data.recordId}`
    return toTitleCase(data.source)
  }, [data])

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot variant="outlined" sx={styles.dot}>
          <Avatar variant="circular" user={data.author} size="md" hover="card" />
        </TimelineDot>
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'normal' }}>
            <b>{data.author.fullName}</b>, {userRoleToLabel[data.author.role]}
          </Typography>
          {showSource && (
            <>
              {href ? (
                <Link component={RouterLink} to={href} target="_blank" rel="noopener noreferrer">
                  {source}
                </Link>
              ) : (
                <Typography variant="subtitle1" sx={{ fontWeight: 'normal', color: 'text.secondary' }}>
                  {source}
                </Typography>
              )}
            </>
          )}
        </Stack>
        <Box sx={styles.message}>
          <Typography>{data.message}</Typography>
        </Box>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {dayjs(data.createdAt).format('L LT')}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}

Note.Loading = ({ showSource = false, isLast = false }) => (
  <TimelineItem>
    <TimelineSeparator>
      <TimelineDot variant="outlined" sx={styles.dot}>
        <Skeleton variant="circular" width={40} height={40} />
      </TimelineDot>
      {isLast ? null : <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent>
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="subtitle1">
          <Skeleton width={200} />
        </Typography>
        {showSource && (
          <Typography>
            <Skeleton width={100} />
          </Typography>
        )}
      </Stack>
      <Box sx={styles.message}>
        <Typography>
          <Skeleton width={300} />
        </Typography>
      </Box>
      <Typography variant="caption">
        <Skeleton width={120} />
      </Typography>
    </TimelineContent>
  </TimelineItem>
)
