import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
import HardBreak from '@tiptap/extension-hard-break'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import Youtube from '@tiptap/extension-youtube'
import { Fragment, Node, Slice } from '@tiptap/pm/model'
import { EditorContent, useEditor } from '@tiptap/react'

import Typography from '@mui/material/Typography'

import { convertPlainTextToJson } from '@shared/utils'

export const baseExtensions = [
  Document,
  Paragraph,
  Text,
  Bold,
  Italic,
  Underline,
  ListItem,
  BulletList,
  HardBreak,
  OrderedList,
  Link.configure({
    openOnClick: true,
    linkOnPaste: true,
    autolink: true,
    defaultProtocol: 'https',
  }),
  History,
  Youtube.configure({ inline: true, width: 320, height: 'auto' }),
  Placeholder.configure({ placeholder: 'Write something...' }),
]

export function containsHtml(text) {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i
  return htmlRegex.test(text)
}

const migration = (content) => {
  try {
    const result = JSON.parse(content)
    if (result?.type === 'doc') return result
  } catch (e) {
    // ignore
  }

  return convertPlainTextToJson(content)
}

// @see https://github.com/ueberdosis/tiptap/issues/775
export const clipboardTextParser = (text, context) => {
  const lines = text.split(/\r\n?|\n/)

  const nodes = lines.map((line) => {
    const content = {
      type: 'paragraph',
      ...(line.length > 0 ? { content: [{ type: 'text', text: line }] } : {}),
    }
    return Node.fromJSON(context.doc.type.schema, content)
  })

  const fragment = Fragment.fromArray(nodes)
  return Slice.maxOpen(fragment)
}

export const RichText = ({ extensions, content, sx = {}, ...rest }) => {
  const editor = useEditor({
    content: migration(content),
    extensions,
    editable: false,
    shouldRerenderOnTransaction: false,
  })

  return (
    <Typography component="span" sx={[richTextStyles, sx]} {...rest}>
      <EditorContent editor={editor} />
    </Typography>
  )
}

export const richTextStyles = {
  width: '100%',
  '& .tiptap': {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    flex: '1 1 auto',
    overflow: 'auto',
    ':focus-visible': {
      outline: 'none',
    },
    '& p': {
      margin: 0,
    },
    '& p.is-editor-empty:first-of-type::before': {
      color: 'text.disabled',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
    '& a': {
      color: 'primary.main',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& ul,ol': {
      p: 0,
      pl: 4,
      m: 0,
    },
    'div[data-youtube-video]': {
      '& iframe': {
        borderRadius: 1,
        border: 0,
      },
      '&.ProseMirror-selectednode': {
        '& iframe': {
          outline: '3px solid',
          outlineColor: 'primary.main',
        },
      },
    },
  },
}
