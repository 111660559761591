import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { useCBO } from '@pages/CBOs/CBO/CBO.hooks'
import { CloseOutlinedIcon } from '@icons'
import DocTitle from '@components/DocTitle'
import { SlideUp } from '@components/Transitions'

import { useCBORuleSets } from '../CBORuleSet.hooks'
import Contacts from './Contacts'
import Coverage from './Coverage'
import Details from './Details'
import Financials from './Financials'
import PatientCare from './PatientCare'
import Program340BDetails from './Program340BDetails'
import Services from './Services'

export default function CBORuleSetDetailsDialog({ id, slide = false, open, onClose }) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={slide ? SlideUp : undefined}
      PaperProps={{ sx: { backgroundColor: 'background.default' } }}
    >
      <DocTitle title="CBO Details" />
      <AppBar>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h4">
            CBO Details
          </Typography>
          <IconButton shape="rounded" color="inherit" onClick={onClose} data-testid="close-calendar">
            <CloseOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: (theme) => `${theme.mixins.toolbar.height}px` }}>
        <CBODetails id={id} />
      </Container>
    </Dialog>
  )
}

function CBODetails({ id }) {
  const { data: cbo, isPending: cboIsPending } = useCBO(id)
  const { data: ruleSets, isPending: ruleSetsArePending } = useCBORuleSets(id)

  return (
    <Paper variant="outlined" sx={{ p: { xs: 2, sm: 1, md: 2 } }}>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Details cbo={cbo} data={ruleSets} isLoading={cboIsPending} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Paper variant="outlined" sx={{ height: '100%' }}>
            <Program340BDetails data={ruleSets} isLoading={ruleSetsArePending} />
            <Financials data={ruleSets} isLoading={ruleSetsArePending} />
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <PatientCare ruleSets={ruleSets} ruleSetsAreLoading={ruleSetsArePending} cbo={cbo} cboIsLoading={cboIsPending} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Services data={ruleSets} isLoading={ruleSetsArePending} />
        </Grid>
        <Grid size={12}>
          <Coverage data={cbo} isLoading={cboIsPending} />
        </Grid>
        <Grid size={12}>
          <Contacts data={ruleSets} isLoading={ruleSetsArePending} />
        </Grid>
      </Grid>
    </Paper>
  )
}
