import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { getTestId } from '@shared/utils'

import { SearchOutlinedIcon } from '@icons'
import SearchInput from '@components/SearchInput'

import { FilterPopper } from '../TableFilters.utils'

StringSearch.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Title of the popper */
  title: PropTypes.string,

  /** Placeholder of the search input */
  placeholder: PropTypes.string,

  /** Callback fired when the value changes */
  onChange: PropTypes.func.isRequired,

  /** String value */
  value: PropTypes.string,
}

/**
 * Button opens a dialog to search a string
 *
 * @example
 * <StringSearch label="Patient" placeholder="Search by name or email" value={value} onChange={handleChange} />
 */
export default function StringSearch({ label, title, placeholder, onChange, value, ...other }) {
  const testId = getTestId(other, `string-search-${label}`)
  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState(value)

  useEffect(() => {
    setSearch(value || '')
  }, [value])

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return
    setOpen(false)
    setSearch(value || '')
  }

  const handleApply = (value) => {
    onChange(value)
    setOpen(false)
  }

  return (
    <>
      <Button
        ref={anchorRef}
        color="inherit"
        endIcon={<SearchOutlinedIcon />}
        onClick={() => setOpen((o) => !o)}
        data-testid={`${testId}-button`}
        {...other}
      >
        {label}
        {value && (
          <Typography
            component="span"
            noWrap
            sx={{
              color: 'primary.main',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
          >
            : {value}
          </Typography>
        )}
      </Button>
      <FilterPopper title={title} open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <SearchInput
          autoFocus
          placeholder={placeholder}
          value={search}
          onChange={setSearch}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              handleApply(search)
            }
          }}
          helperText="Press 'Enter' to apply"
          data-testid={testId}
        />
        <Stack direction="row" spacing={1}>
          <Button onClick={() => handleApply('')} data-testid={`${testId}-clear`}>
            Clear
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={handleClose} data-testid={`${testId}-cancel`}>
            Cancel
          </Button>
          <Button onClick={() => handleApply(search)} variant="contained" data-testid={`${testId}-apply`}>
            Apply
          </Button>
        </Stack>
      </FilterPopper>
    </>
  )
}
