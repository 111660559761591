import { Outlet } from 'react-router'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'

import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    backgroundColor: (theme) => theme.palette.grey[100],
  },
  main: {
    pt: (theme) => `${theme.mixins.toolbar.height}px`,
    minHeight: '100vh',
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
  },
}

/**
 * Layout for authenticated users.
 * It contains the sidebar, header and the main content.
 */
export default function Layout() {
  return (
    <Fade in>
      <Box sx={styles.container}>
        <Header />
        <Sidebar />
        <Stack sx={styles.main}>
          <Stack sx={styles.content}>
            <Outlet />
          </Stack>
          <Footer />
        </Stack>
      </Box>
    </Fade>
  )
}
