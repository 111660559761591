import { useState } from 'react'
import { useController } from 'react-hook-form'
import partition from 'lodash/partition'

import Box from '@mui/material/Box'
import CardActionArea from '@mui/material/CardActionArea'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import usePatientAvailableProviders from '@hooks/usePatientAvailableProviders'
import { PeopleIcon, UserOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'

const styles = {
  card: {
    width: '50%',
  },
  selected: {
    backgroundColor: 'primary.lighter',
    outline: (theme) => `2px solid ${theme.palette.primary.main}`,
    outlineOffset: -2,
  },
  list: {
    display: 'flex',
    listStyle: 'none',
    m: 0,
    p: 0,
  },
  user: {
    alignItems: 'center',
    borderRadius: 1,
    display: 'flex',
    flex: '0 0 auto',
    gap: 1,
    p: 1,
  },
  info: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  text: {
    flex: '1 1 auto',
  },
}

export default function ProviderSection({ patient }) {
  const timeslotField = useController({ name: 'timeslot' })
  const providerField = useController({ name: 'provider' })
  const timeslot = timeslotField.field.value
  const provider = providerField.field.value

  return (
    <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
      <AnyProvider isSelected={!provider} onClick={() => providerField.field.onChange(null)} />
      <SelectProvider
        patient={patient}
        provider={provider}
        onChange={(provider) => {
          providerField.field.onChange(provider)
          // reset previously selected timeslot if it belongs to a different provider
          if (timeslot && timeslot.provider.id !== provider.id) {
            timeslotField.field.onChange(null)
          }
        }}
      />
    </Stack>
  )
}

function Card({ children, isSelected, onClick }) {
  return (
    <Paper variant="outlined" sx={[styles.card, isSelected ? styles.selected : undefined]}>
      <CardActionArea onClick={onClick} sx={{ height: '100%' }}>
        <Stack direction="row" spacing={2} sx={{ p: 1, height: '100%' }}>
          {children}
        </Stack>
      </CardActionArea>
    </Paper>
  )
}

function AnyProvider({ isSelected, onClick }) {
  return (
    <Card isSelected={isSelected} onClick={onClick}>
      <Avatar.Icon size="lg">
        <PeopleIcon />
      </Avatar.Icon>
      <Stack>
        <Typography variant="h4">Any Provider</Typography>
        <Typography>Schedule with any available provider at your convenience</Typography>
      </Stack>
    </Card>
  )
}

function SelectProvider({ patient, provider, onChange }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Card isSelected={Boolean(provider)} onClick={() => setOpen(true)}>
        {provider ? (
          <Avatar variant="circular" user={provider} size="lg" />
        ) : (
          <Avatar.Icon size="lg">
            <UserOutlinedIcon />
          </Avatar.Icon>
        )}
        <Stack sx={{ display: 'grid' }}>
          <Typography variant="h4" noWrap>
            {provider ? provider.fullName : 'Pick a Specific Provider'}
          </Typography>
          <Typography>
            {provider ? 'Shows timeslots for the chosen provider' : 'Pick this to select timeslots for a specific provider'}
          </Typography>
        </Stack>
      </Card>
      <ProvidersDialog patient={patient} open={open} onClose={() => setOpen(false)} onSelect={onChange} />
    </>
  )
}

function ProvidersDialog({ patient, open, onClose, onSelect }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} scroll="body" PaperProps={{ sx: { verticalAlign: 'top' } }}>
      <DialogTitle onClose={onClose}>Providers</DialogTitle>
      <DialogContent>
        <Providers
          patient={patient}
          onSelect={(provider) => {
            onClose()
            onSelect(provider)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

function Providers({ patient, onSelect }) {
  const { data, isPending } = usePatientAvailableProviders(patient?.id, undefined, {
    enabled: Boolean(patient?.id),
  })

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  const assignedProvider = patient?.provider?.id
  const [assigned, available] = partition(data, (provider) => provider.id === assignedProvider)

  return (
    <Stack component="ul" spacing={1} sx={styles.list}>
      {showLoading && (
        <>
          <Provider.Loading />
          <Provider.Loading />
          <Provider.Loading />
        </>
      )}
      {showEmpty && <Typography variant="h4">No providers available</Typography>}
      {showData && (
        <>
          {assigned.length > 0 && (
            <Stack>
              <Typography variant="h5" sx={{ color: 'text.secondary' }}>
                Assigned Provider
              </Typography>
              <Provider data={assigned[0]} onClick={() => onSelect(assigned[0])} />
            </Stack>
          )}
          {available.length > 0 && (
            <Stack>
              <Typography variant="h5" sx={{ color: 'text.secondary' }}>
                Available Providers
              </Typography>
              {available.map((provider) => (
                <Provider key={provider.id} data={provider} onClick={() => onSelect(provider)} />
              ))}
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
}

function Provider({ data, onClick }) {
  return (
    <Box component="li">
      <CardActionArea onClick={onClick} sx={styles.user}>
        <Avatar variant="rounded" user={data} />
        <Box sx={styles.info}>
          <Typography noWrap variant="body2" sx={styles.text}>
            {data.fullName}
          </Typography>
          <Typography noWrap variant="body2" sx={[styles.text, { color: 'text.secondary' }]}>
            {data.servicingNewPatients ? 'Accepting New Patients' : 'Not Accepting New Patients'}
          </Typography>
        </Box>
      </CardActionArea>
    </Box>
  )
}

Provider.Loading = function ProviderLoading() {
  return (
    <Box component="li">
      <CardActionArea sx={styles.user}>
        <Skeleton variant="rounded" width={40} height={40} />
        <Box sx={styles.info}>
          <Typography noWrap variant="body2" sx={styles.text}>
            <Skeleton width={200} />
          </Typography>
          <Typography noWrap variant="body2" sx={[styles.text, { color: 'text.secondary' }]}>
            <Skeleton width={300} />
          </Typography>
        </Box>
      </CardActionArea>
    </Box>
  )
}
