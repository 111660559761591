import { useState } from 'react'
import { DndContext } from '@dnd-kit/core'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import Mask from '@shared/components/src/Mask'
import { useMe } from '@shared/providers/src/MeProvider'
import { VoiceCallStatus } from '@shared/utils'

import VoiceCallProvider, { useVoiceCallState } from '@providers/VoiceCallProvider'
import { useVoiceWidgetState } from '@providers/VoiceWidgetStateProvider'
import { AudioFilledIcon, AudioMutedIcon, BackspaceIcon, CloseOutlinedIcon, PhoneFilledIcon } from '@icons'

import { useDraggableWidget } from './VoiceWidget.hooks'
import { getLabel, Handle, MicPermission, styles } from './VoiceWidget.utils'

export default function VoiceWidget({ open, onClose }) {
  return (
    <Fade in={open} unmountOnExit>
      <div>
        <DndContext>
          <DraggableWidget onClose={onClose} />
        </DndContext>
      </div>
    </Fade>
  )
}

function DraggableWidget({ onClose }) {
  const { position, setNodeRef, listeners, attributes, style } = useDraggableWidget()

  return (
    <Box ref={setNodeRef} sx={{ position: 'fixed', zIndex: 9999, top: position.y, left: position.x }} style={style}>
      <Paper elevation={3} sx={styles.container}>
        <Handle {...listeners} {...attributes} />
        <IconButton color="secondary" shape="rounded" onClick={onClose} sx={styles.close} data-testid="close-voice-widget">
          <CloseOutlinedIcon />
        </IconButton>
        <MicPermission>
          <VoiceCallProvider disableHistory allowInAppNavigation externalCall>
            <Content />
          </VoiceCallProvider>
        </MicPermission>
      </Paper>
    </Box>
  )
}

function Content() {
  const me = useMe()
  const { makeCall, hangUp, twilio, error, call } = useVoiceCallState()

  const { phone, setPhone } = useVoiceWidgetState()
  const [muted, setMuted] = useState(twilio.call?.isMuted() ?? false)

  const isCallInProgress = Boolean(twilio.call)
  const status = call.data?.status

  const handlePadDigit = (digit) => {
    if (isCallInProgress) return twilio.call.sendDigits(digit)

    if (phone.length >= 10 || ['*', '#'].includes(digit)) return
    setPhone((p) => p + digit)
  }

  const handleBackspace = () => {
    if (isCallInProgress) return
    setPhone((p) => p.slice(0, -1))
  }

  const handleDial = () => {
    if (!twilio.device && !error) return

    if (isCallInProgress) return hangUp()

    if (!phone) return
    const externalPhoneNumber = `+1${phone}`
    makeCall(externalPhoneNumber, { authorId: me.id, externalPhoneNumber })
  }

  return (
    <Stack>
      <Typography variant="body2" sx={{ color: status === VoiceCallStatus.Failed ? 'error.main' : 'text.secondary', lineHeight: 1 }}>
        {getLabel(status, Boolean(twilio.call))}
      </Typography>
      <InputBase
        fullWidth
        autoFocus
        readOnly={isCallInProgress}
        placeholder="(123) 456-7890"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        type="text"
        autoComplete="tel-national"
        inputComponent={Mask.Phone}
        inputProps={{ 'data-testid': 'phone-input' }}
      />
      <Box sx={styles.pad}>
        <PadButton onClick={() => handlePadDigit('1')} data-testid="pad-button-1">
          1
        </PadButton>
        <PadButton onClick={() => handlePadDigit('2')} data-testid="pad-button-2">
          2
        </PadButton>
        <PadButton onClick={() => handlePadDigit('3')} data-testid="pad-button-3">
          3
        </PadButton>
        <PadButton onClick={() => handlePadDigit('4')} data-testid="pad-button-4">
          4
        </PadButton>
        <PadButton onClick={() => handlePadDigit('5')} data-testid="pad-button-5">
          5
        </PadButton>
        <PadButton onClick={() => handlePadDigit('6')} data-testid="pad-button-6">
          6
        </PadButton>
        <PadButton onClick={() => handlePadDigit('7')} data-testid="pad-button-7">
          7
        </PadButton>
        <PadButton onClick={() => handlePadDigit('8')} data-testid="pad-button-8">
          8
        </PadButton>
        <PadButton onClick={() => handlePadDigit('9')} data-testid="pad-button-9">
          9
        </PadButton>
        <PadButton onClick={() => handlePadDigit('*')} data-testid="pad-button-*">
          *
        </PadButton>
        <PadButton onClick={() => handlePadDigit('0')} data-testid="pad-button-0">
          0
        </PadButton>
        <PadButton onClick={() => handlePadDigit('#')} data-testid="pad-button-#">
          #
        </PadButton>
        {isCallInProgress ? (
          <IconButton
            size="large"
            color="primary"
            variant={muted ? 'outlined' : 'contained'}
            shape="rounded"
            disabled={!twilio.device || !twilio.call}
            onClick={() =>
              setMuted((value) => {
                const newValue = !value
                twilio.call?.mute(newValue)
                return newValue
              })
            }
            data-testid="mute-button"
            data-test-active={muted}
          >
            {muted ? <AudioMutedIcon fontSize="18px" /> : <AudioFilledIcon />}
          </IconButton>
        ) : (
          <Box sx={{ width: 44, height: 44 }} />
        )}
        <IconButton
          size="large"
          color={isCallInProgress ? 'error' : 'success'}
          variant="contained"
          shape="rounded"
          disabled={!twilio.device && !error}
          onClick={handleDial}
          data-testid={isCallInProgress ? 'hangup-button' : 'dial-button'}
        >
          {isCallInProgress ? <PhoneFilledIcon rotate={-135} /> : <PhoneFilledIcon />}
        </IconButton>
        <PadButton variant="text" onClick={handleBackspace} data-testid="backspace-button">
          <BackspaceIcon style={{ fontSize: 21 }} />
        </PadButton>
      </Box>
    </Stack>
  )
}

function PadButton(props) {
  return <IconButton variant="outlined" size="large" color="secondary" shape="rounded" {...props} />
}
