import { useState } from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import IconButton from '@components/_mui/IconButton'

import { EyeInvisibleOutlinedIcon, EyeOutlinedIcon } from '@icons'
import InputControl from '@components/InputControl'

export default function PasswordField({ formik }) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <InputControl field="password" formikProps={formik}>
      <TextField
        autoFocus
        required
        fullWidth
        type={showPassword ? 'text' : 'password'}
        placeholder="Enter your password"
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'background.paper',
          },
        }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((sp) => !sp)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                  color="secondary"
                  data-testid="show-password-btn"
                  data-test-visible={showPassword}
                >
                  {showPassword ? <EyeOutlinedIcon /> : <EyeInvisibleOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
      />
    </InputControl>
  )
}
