import dayjs from 'dayjs'

import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { AppointmentScheduleTypes } from '@shared/utils'

import { AppointmentIcon } from '@icons'

export function Encounter({ data }) {
  return (
    <Stack spacing={-0.5}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <AppointmentIcon type={data.type} />
        <Typography>{data.visitReason}</Typography>
      </Stack>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {SchedTypeLabel[data.schedType]}
      </Typography>
    </Stack>
  )
}

export function Date({ data }) {
  if (!data.start && !data.scheduled) return null

  const date = data.start || data.scheduled

  return (
    <Stack spacing={-0.5}>
      <Typography>{dayjs(date).format('L')}</Typography>
      <Typography>{dayjs(date).format('LT')}</Typography>
    </Stack>
  )
}

export const SchedTypeLabel = {
  [AppointmentScheduleTypes.Scheduled]: 'Scheduled',
  [AppointmentScheduleTypes.Unscheduled]: 'Unscheduled',
  [AppointmentScheduleTypes.Async]: 'Async',
  [AppointmentScheduleTypes.OnDemand]: 'On-Demand',
}
