import Grid from '@mui/material/Grid2'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

import Property from '@components/Property'

import { Section } from '../CBORuleSet.utils'

export default function PatientCare({ ruleSets, ruleSetsAreLoading, cbo, cboIsLoading }) {
  return (
    <Section paper title="Patient Care">
      <Grid container spacing={1}>
        <Grid size={8}>
          <Stack spacing={1}>
            <Property
              label="Q Care Plus Services"
              value={
                cboIsLoading ? (
                  <Skeleton width={80} />
                ) : (
                  cbo?.cbosProviderTypes
                    ?.map((type) => [type.name, type.onDemand ? '(On Demand)' : ''].filter(Boolean).join(' '))
                    .join(', ') || '-'
                )
              }
            />
            <Property
              label="Lab Vendors Available"
              value={cboIsLoading ? <Skeleton width={80} /> : cbo?.labVendors?.map((type) => type.name).join(', ') || '-'}
            />
            <Property label="STI Reporting" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.stiReporting} />
          </Stack>
        </Grid>
        <Grid size={4}>
          <Stack spacing={1}>
            <Property label="pos. Chlamydia" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.posChlamydia} />
            <Property label="pos. Gonorrhea" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.posGonorrhea} />
            <Property label="pos. Syphilis" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.posSyphilis} />
            <Property label="pos. HIV" value={ruleSetsAreLoading ? <Skeleton width={100} /> : ruleSets?.patientCare?.posHIV} />
          </Stack>
        </Grid>
      </Grid>
    </Section>
  )
}
