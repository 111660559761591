import SvgIcon from '@mui/material/SvgIcon'

export default function MedicalServiceIcon(props) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path fill="none" d="M0 0h16v16H0z" />
      <path d="M8.667 6.667H7.334v2h-2V10h2v2h1.333v-2h2V8.667h-2v-2z" fill="currentColor" />
      <path
        d="M13.334 4h-2.667V2.667c0-.733-.6-1.333-1.333-1.333H6.667c-.733 0-1.333.6-1.333 1.333V4H2.667c-.733 0-1.333.6-1.333 1.333v8c0 .734.6 1.334 1.333 1.334h10.666c.734 0 1.334-.6 1.334-1.333v-8c0-.734-.6-1.334-1.333-1.334zM6.667 2.667h2.667V4H6.667V2.667zm6.667 10.666H2.667v-8h10.667v8z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
