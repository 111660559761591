import { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import usePubNub from '@shared/hooks/src/usePubNub'
import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { useMe } from '@shared/providers/src/MeProvider'
import { handleErrorSilently } from '@shared/utils'

import { styles, Title } from '../Notifications.utils'
import { usePlatformNotification } from './PlatformNotifications.hooks'

/**
 * Handle the display of a Platform notifications.
 */
export default function PlatformNotifications() {
  const [notification, setNotification] = useState()
  const [seen, setSeen] = useLocalStorage('platform-notification-seen', false)

  const me = useMe()
  const query = usePlatformNotification({
    gcTime: 0,
    staleTime: 0,
    enabled: !seen && !notification,
  })

  useQueryEvents(query, { onSuccess: setNotification })
  usePubNub('platform_notifications', ({ attributes }) => {
    try {
      const newNotification = JSON.parse(attributes)
      if (!newNotification.roles.includes(me.role)) return

      const oldNotification = notification
      setNotification(newNotification)
      if (oldNotification?.id === newNotification.id) return

      setSeen(false)
    } catch (e) {
      handleErrorSilently(e)
    }
  })

  return (
    <Snackbar
      open={Boolean(notification) && !seen}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') setSeen(true)
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div>
        <Stack
          spacing={1}
          sx={[
            styles.container,
            {
              background: 'none',
              minWidth: 'unset',
              maxWidth: 'unset',
              width: 'unset',
              backgroundColor: severityToBackground[notification?.severity],
            },
          ]}
        >
          <Title label={notification?.title} onClose={() => setSeen(true)} />
          <Typography>{notification?.description}</Typography>
        </Stack>
      </div>
    </Snackbar>
  )
}

const severityToBackground = {
  info: 'primary.main',
  success: 'success.main',
  warning: 'warning.main',
  error: 'error.main',
}
