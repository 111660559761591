import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK } from '@shared/utils'

export function useUser(userId) {
  return useQuery({
    queryKey: QK.users.id(userId).card,
    queryFn: () => API.users.id(userId).card({ skipHandling: true }),
    enabled: Boolean(userId),
  })
}
