import AvatarMui from '@mui/material/Avatar'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

export default function Section({ mini = false, paper = false, title, action, icon, children }) {
  const content = paper ? <Paper variant="outlined">{children}</Paper> : children

  if (mini) {
    return (
      <Stack spacing={1}>
        <Title mini title={title} icon={icon} action={action} />
        {content}
      </Stack>
    )
  }

  return (
    <Paper variant="outlined">
      <Stack spacing={{ xs: 1, lg: 2 }} sx={{ p: { xs: 2, lg: 3 } }}>
        <Title title={title} icon={icon} action={action} />
        {content}
      </Stack>
    </Paper>
  )
}

function Title({ mini = false, title, icon, action }) {
  if (!title) return null

  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        {icon && <AvatarMui sx={{ backgroundColor: 'background.paper', color: 'text.primary' }}>{icon}</AvatarMui>}
        <Typography variant={mini ? 'h5' : 'h4'}>{title}</Typography>
      </Stack>
      {action}
    </Stack>
  )
}
