import { useMemo } from 'react'
import PropTypes from 'prop-types'

import MenuItem from '@mui/material/MenuItem'
import PaginationItem from '@mui/material/PaginationItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'

import usePageParams from '@shared/hooks/src/usePageParams'
import { getTestId } from '@shared/utils'

Pagination.propTypes = {
  /** The id of the pagination for uniqueness if multiple paginations are present on the page */
  id: PropTypes.string,

  /** Can show ellipsis indicator when loading */
  loading: PropTypes.bool,

  /** Can disable the next page button */
  last: PropTypes.bool,

  /** Can disable the rows per page option */
  disableRowsPerPage: PropTypes.bool,

  /** Override the page number */
  page: PropTypes.number,

  /** Override the rows per page */
  perPage: PropTypes.number,

  /** Override the page params function */
  setPageParams: PropTypes.func,
}

const itemStyle = { backgroundColor: 'background.paper' }

/**
 * Display a pagination control.
 * Works with query params by default if no overrides are provided.
 *
 * @example with query params
 * <Pagination id="unique-key-if-required" loading={isLoading} last={data?.length < perPage} />
 *
 * @example with react state
 * const [{ page, perPage }, setPageParams] = useState({ page: 1, perPage: 5 })
 * <Pagination page={page} perPage={perPage} setPageParams={setPageParams} loading={isLoading} last={data?.length < perPage} />
 */
export default function Pagination({
  id,
  page: overridePage,
  perPage: overridePerPage,
  setPageParams: setOverridePageParams,
  loading = false,
  last = false,
  disableRowsPerPage = false,
  ...rest
}) {
  const testId = getTestId(rest, 'pagination')
  const [queryPage, queryPerPage, setQueryPageParams] = usePageParams({ id })

  const { page, perPage, setPageParams } = useMemo(() => {
    if (overridePage && overridePerPage && setOverridePageParams) {
      return { page: overridePage, perPage: overridePerPage, setPageParams: setOverridePageParams }
    }
    return { page: queryPage, perPage: queryPerPage, setPageParams: setQueryPageParams }
  }, [overridePage, overridePerPage, queryPage, queryPerPage, setOverridePageParams, setQueryPageParams])

  return (
    <Stack spacing={1} direction="row" {...rest} sx={[{ alignItems: 'center' }, ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx])]}>
      {/* Rows per page */}
      {!disableRowsPerPage && (
        <Select
          size="small"
          value={perPage}
          onChange={(e) => setPageParams({ page: 1, perPage: e.target.value })}
          data-testid={`${testId}-rows`}
        >
          <MenuItem value={10}>10 / page</MenuItem>
          <MenuItem value={25}>25 / page</MenuItem>
          <MenuItem value={50}>50 / page</MenuItem>
        </Select>
      )}
      <PaginationItem
        type="previous"
        disabled={loading || page === 1}
        shape="rounded"
        variant="combined"
        onClick={() => setPageParams({ page: page - 1, perPage })}
        aria-label="Go to previous page"
        data-testid={`${testId}-previous`}
        sx={itemStyle}
      />
      <PaginationItem
        type={loading ? 'start-ellipsis' : 'page'}
        page={page}
        shape="rounded"
        variant="combined"
        aria-label={`page ${page}`}
        data-testid={`${testId}-page-${page}`}
        sx={itemStyle}
      />
      <PaginationItem
        type="next"
        disabled={loading || last}
        shape="rounded"
        variant="combined"
        onClick={() => setPageParams({ page: page + 1, perPage })}
        aria-label="Go to next page"
        data-testid={`${testId}-next`}
        sx={itemStyle}
      />
    </Stack>
  )
}
