import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { useTheme } from '@mui/material/styles'

import LinearProgress from '@components/LinearProgress'
import TestId from '@components/TestId'

Tile.propTypes = {
  /* Can show linear progress on top */
  refreshing: PropTypes.bool,

  /* Can change color of the text to be contrast */
  contrast: PropTypes.bool,

  /* Can change the background color of tile header */
  highlight: PropTypes.string,

  /** A Tile can have a darkened background */
  subdued: PropTypes.bool,

  /* Title of Card */
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),

  /* Can show additional node on the right side of header */
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
}

/**
 * Wrapper around MUI Card which provides default styles
 *
 * @example
 * <Tile header="Welcome to the Thunderdome">
 *    <Typography variant="body2">
 *        Duis consequat enim vitae ex pulvinar tempor. Duis consectetur pharetra dui. Duis in purus molestie, posuere
 *        arcu non, tincidunt nulla.
 *    </Typography>
 * </Tile>
 */
export default function Tile({ title, action, contrast = false, highlight, subdued = false, refreshing = false, sx = {}, children }) {
  // FIXME: This should take a 'disablePadding' prop and use that instead of checking the children type
  const theme = useTheme()

  const headerBgColor = useMemo(() => {
    if (highlight) return highlight
    if (subdued) return theme.palette.grey[100]
    return 'background.paper'
  }, [highlight, subdued, theme.palette.grey])

  const bodyBgColor = useMemo(() => {
    if (subdued) return theme.palette.grey[100]
    return 'background.paper'
  }, [subdued, theme.palette.grey])

  return (
    <Card
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'relative',
          borderRadius: 1,
          boxShadow: 'none',
          border: '1px solid',
          borderColor: 'divider',
          '& .MuiCardActions-root': {
            padding: 0,
          },
          '& .MuiCardContent-root': {
            position: 'relative',
            borderRadius: '0 0 4px 4px',
            backgroundColor: bodyBgColor,
            padding: typeof children === 'string' ? 3 : 0,
            '&:last-child': {
              paddingBottom: typeof children === 'string' ? 2 : 0,
            },
          },
          '& .MuiCardHeader-root': {
            borderBottom: '1px solid',
            borderColor: 'divider',
            backgroundColor: headerBgColor,
            color: contrast ? 'primary.contrastText' : 'text.primary',
            py: typeof title === 'string' ? 2 : 0,
            px: typeof title === 'string' ? 3 : 0,
          },
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
          },
        },
        sx,
      ]}
    >
      {title && (
        <TestId id="title">
          <CardHeader
            title={title}
            action={action}
            slotProps={{
              title: { variant: 'h5', fontWeight: 'bold' },
            }}
          />
        </TestId>
      )}
      <LinearProgress loading={refreshing} color="warning" />
      {children && (
        <TestId id="content">
          <CardContent sx={{ flex: 1, overflow: 'auto' }}>{children}</CardContent>
        </TestId>
      )}
    </Card>
  )
}
