import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

export const CycleOptions = {
  'First Q Care Plus cycle': 'First Q Care Plus quarter',
  'All cycles': 'All quarters',
}

export function Section({ paper, title, children }) {
  const content = (
    <Stack spacing={{ xs: 2, sm: 1, md: 2 }} sx={{ p: { xs: 3, sm: 1, md: 3 } }}>
      <Typography variant="h4" sx={{ fontWeight: 'light' }}>
        {title}
      </Typography>
      <div>{children}</div>
    </Stack>
  )

  if (paper) {
    return (
      <Paper variant="outlined" sx={{ height: '100%' }}>
        {content}
      </Paper>
    )
  }

  return content
}
