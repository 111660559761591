import toast from 'react-hot-toast'

import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { useSendReminderMessage } from '@shared/messaging/src/hooks'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { mapCache, MessageReminderCategories, MessageReminderRelatedModels, QK } from '@shared/utils'

import PhotoIdReminderButton from './PhotoIdReminderButton'

export default function PhotoIdReminderOnAppointment({ readOnly = false, patient, appointment }) {
  const me = useMe()
  const sendMessageReminder = useSendReminderMessage(patient.id)

  if (patient.hasPhotoIdentification) return null

  const handleSubmit = (message) => {
    return sendMessageReminder
      .mutateAsync({
        message,
        category: MessageReminderCategories.UploadPhotoId,
        related_model_type: MessageReminderRelatedModels.Appointment,
        related_model_id: appointment.id,
      })
      .then(({ relatedModel }) => {
        toast.success('Message sent')

        const updater = mapCache((old) => (old.id === relatedModel.id ? { ...old, ...relatedModel } : old))
        queryClient.setQueriesData({ queryKey: QK.providers.id(me.provider.id).dashboard.appointments.lists }, updater)
        queryClient.setQueriesData({ queryKey: QK.providers.id(me.provider.id).appointments.lists }, updater)
      })
  }

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography color="error">Missing photo ID</Typography>
      {!readOnly && appointment && (
        <PhotoIdReminderButton patient={patient} disabled={appointment.remindedUploadPhotoId} onSubmit={handleSubmit} />
      )}
    </Stack>
  )
}
