import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@components/_mui/Typography'

import { useTracksContext } from '@shared/twilio/src/providers/TracksProvider'
import { backgroundConfig } from '@shared/twilio/src/providers/VideoProvider/useBackgroundSettings'

export default function BackgroundList() {
  const imageNames = backgroundConfig.imageNames

  const { backgroundSettings, setBackgroundSettings } = useTracksContext()
  const { type } = backgroundSettings

  const value = type === 'image' ? backgroundSettings.index : type

  const handleChange = (e) => {
    const value = e.target.value

    if (value === 'blur') {
      setBackgroundSettings({ type: 'blur' })
    } else if (value === 'none') {
      setBackgroundSettings({ type: 'none' })
    } else {
      setBackgroundSettings({ type: 'image', index: value })
    }
  }

  return (
    <div>
      <FormControl fullWidth>
        <Typography gutterBottom sx={{ color: 'text.secondary' }}>
          Background
        </Typography>
        <Select variant="outlined" value={value} onChange={handleChange}>
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="blur">Blur</MenuItem>
          {imageNames.map((name, index) => (
            <MenuItem key={name} value={index}>
              {imageNames[index]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
