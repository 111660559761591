import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import MentionExtensionBase from '@tiptap/extension-mention'
import { mergeAttributes, NodeViewWrapper, ReactNodeViewRenderer, ReactRenderer } from '@tiptap/react'
import partition from 'lodash/partition'
import { useDebounce } from 'usehooks-ts'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Typography from '@components/_mui/Typography'

import API from '@shared/services/src/API'
import { QK, userRoleToLabel } from '@shared/utils'

import { MedicalServiceIcon, PeopleIcon } from '@icons'
import Avatar, { Offline } from '@components/Avatar'
import { UserCardPopper } from '@components/UserCard'

const MentionType = {
  User: 'user',
  Pharmacy: 'pharmacy',
  CareTeam: 'careteam',
}

const styles = {
  mention: {
    display: 'inline-flex',
    px: 0.5,
    borderRadius: 1,
  },
  list: {
    width: '100%',
    maxWidth: 400,
  },
  subheader: {
    lineHeight: (theme) => theme.spacing(4),
    borderBottom: '1px solid',
    borderColor: 'divider',
  },
}

// Custom node view component
const Mention = (props) => {
  const { id, label, type } = props.node.attrs

  return (
    <NodeViewWrapper style={{ display: 'inline' }}>
      <Box component="span" sx={[styles.mention, { backgroundColor: type === MentionType.User ? 'primary.200' : 'warning.lighter' }]}>
        {type === MentionType.User && (
          <UserCardPopper userId={id}>
            <Typography component="span" sx={{ color: 'primary.main' }}>
              @{label}
            </Typography>
          </UserCardPopper>
        )}
        {(type === MentionType.Pharmacy || type === MentionType.CareTeam) && (
          <Typography component="span" sx={{ color: 'warning.dark' }}>
            @{label}
          </Typography>
        )}
      </Box>
    </NodeViewWrapper>
  )
}

// Custom mention popup component
const UsersList = React.forwardRef(({ clientRect, command, query: search, conversationId }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const referenceEl = useMemo(() => (clientRect ? { getBoundingClientRect: clientRect } : null), [clientRect])
  const debouncedSearch = useDebounce(search, 300)

  const query = { term: debouncedSearch, limit: 6 }

  const { data } = useQuery({
    queryKey: QK.conversations.id(conversationId).users.search.list(query),
    queryFn: () => API.conversations.id(conversationId).users.search(query),
    enabled: Boolean(conversationId) && conversationId !== 'new',
    placeholderData: keepPreviousData,
    select: (users) => {
      const utils = []
      if (MentionType.CareTeam.startsWith(debouncedSearch)) {
        utils.push({ id: MentionType.CareTeam, label: 'careteam', type: MentionType.CareTeam })
      }
      if (MentionType.Pharmacy.startsWith(debouncedSearch)) {
        utils.push({ id: MentionType.Pharmacy, label: 'pharmacy', type: MentionType.Pharmacy })
      }
      return [...utils, ...users.map((user) => ({ id: user.id, label: user.fullName, type: MentionType.User, data: user }))]
    },
  })

  // Reset selectedIndex when data changes
  useEffect(() => setSelectedIndex(0), [data])

  const selectItem = (index) => {
    const item = data?.[index]
    if (item) command(item)
  }

  const upHandler = () => setSelectedIndex((selectedIndex + data?.length - 1) % data?.length)
  const downHandler = () => setSelectedIndex((selectedIndex + 1) % data?.length)
  const enterHandler = () => selectItem(selectedIndex)

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  const [userItems, groupItems] = partition(data || [], (item) => item.type === MentionType.User)

  return (
    <Popper id="check" placement="top-start" open={Boolean(clientRect) && data?.length > 0} anchorEl={referenceEl} sx={{ zIndex: 1300 }}>
      <Paper>
        <List dense disablePadding sx={styles.list}>
          {groupItems.length > 0 && (
            <>
              <ListSubheader disableSticky sx={styles.subheader}>
                Groups
              </ListSubheader>
              {groupItems.map(({ id, label, type }, index) => (
                <ListItemButton key={`group-${index}`} divider selected={selectedIndex === index} onClick={() => selectItem(index)}>
                  <ListItemIcon>
                    {id === MentionType.Pharmacy && <MedicalServiceIcon style={{ fontSize: 20 }} />}
                    {id === MentionType.CareTeam && <PeopleIcon style={{ fontSize: 20 }} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={`@${label}`}
                    slotProps={{
                      primary: { variant: 'h4', fontWeight: 'normal' },
                    }}
                  />
                </ListItemButton>
              ))}
            </>
          )}

          {userItems.length > 0 && (
            <>
              <ListSubheader disableSticky sx={styles.subheader}>
                Users
              </ListSubheader>
              {userItems.map(({ id, label, type, data }, index) => {
                const actualIndex = groupItems.length + index
                return (
                  <ListItemButton
                    key={`user-${index}`}
                    divider
                    selected={selectedIndex === actualIndex}
                    onClick={() => selectItem(actualIndex)}
                  >
                    <Offline in={Boolean(data.outOfOfficeMessage)} overlap="rectangular">
                      <Avatar hover="card" user={data} size="md" />
                    </Offline>
                    <ListItemText
                      primary={data.fullName}
                      secondary={userRoleToLabel[data.role]}
                      sx={{ pl: 1, my: 0 }}
                      slotProps={{
                        primary: { lineHeight: 1.5 },
                        secondary: { lineHeight: 1.5 },
                      }}
                    />
                  </ListItemButton>
                )
              })}
            </>
          )}
        </List>
      </Paper>
    </Popper>
  )
})

// Extend and configure the mention extension
const MentionExtension = (conversationId) => {
  const renderUsersPopup = () => {
    let component

    return {
      onStart: (props) => {
        component = new ReactRenderer(UsersList, {
          props: { ...props, conversationId },
          editor: props.editor,
        })
      },

      onUpdate(props) {
        component?.updateProps({ ...props, conversationId })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          component?.destroy()
          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        component.destroy()
      },
    }
  }

  return MentionExtensionBase.extend({
    addNodeView() {
      return ReactNodeViewRenderer(Mention)
    },
    parseHTML() {
      return [{ tag: 'mention-component' }]
    },
    renderHTML({ HTMLAttributes }) {
      return ['mention-component', mergeAttributes(HTMLAttributes)]
    },
    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-id'),
          renderHTML: (attributes) => {
            if (!attributes.id) return {}
            return { 'data-id': attributes.id }
          },
        },
        label: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-label'),
          renderHTML: (attributes) => {
            if (!attributes.label) return {}
            return { 'data-label': attributes.label }
          },
        },
        type: {
          default: MentionType.User,
          parseHTML: (element) => element.getAttribute('data-type'),
          renderHTML: (attributes) => {
            if (!attributes.type) return {}
            return { 'data-type': attributes.type }
          },
        },
      }
    },
  }).configure({
    deleteTriggerWithBackspace: true,
    suggestion: { allowSpaces: true, render: renderUsersPopup },
  })
}

export default MentionExtension
