import dayjs from 'dayjs'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Skeleton from '@mui/material/Skeleton'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { useAppointments } from './Availability.hooks'

export function AppointmentNotice({ providerId, dateRange, time, open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Appointment Scheduled</DialogTitle>
      <DialogContent dividers>
        <AppointmentNoticeContent providerId={providerId} dateRange={dateRange} time={time} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function AppointmentNoticeContent({ providerId, dateRange, time }) {
  const { data, isPending } = useAppointments(providerId, dateRange, time)

  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Typography sx={{ whiteSpace: 'pre-line' }}>
      There are currently appointments scheduled that require availability during the time that would be removed.
      {(showLoading || showData) && (
        <>
          <br />
          <br />
          Appointments:
        </>
      )}
      {showLoading && (
        <>
          <Skeleton width={120} />
          <Skeleton width={100} />
        </>
      )}
      {showData &&
        data.map((appointment) => (
          <Typography key={appointment.id} component="li">
            {dayjs(appointment.start).tz(window.timezone).format('MMMM D @ LT')}
          </Typography>
        ))}
    </Typography>
  )
}
