import { Navigate } from 'react-router'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import API from '@shared/services/src/API'
import { QK, UserRole } from '@shared/utils'

export default function PatientAccessGuard({ redirect = false, patientId, children }) {
  const isAllowed = usePatientAccessible(patientId)

  if (isAllowed) return children
  if (redirect) return <Navigate replace to="/401" />
  return null
}

export function usePatientAccessible(patientId) {
  const me = useMe()

  const enabledByRole = [UserRole.ClinicalLead, UserRole.CBO].includes(me.role)

  const { data } = useQuery({
    queryKey: QK.patients.id(patientId).accessible,
    queryFn: () => API.patients.id(patientId).accessible(),
    enabled: enabledByRole && Boolean(patientId),
    staleTime: 24 * 60 * 60 * 1000, // data is considered fresh for 24 hours
    gcTime: 24 * 60 * 60 * 1000, // data will be garbage collected after 24 hours
    select: (data) => data.accessible,
  })

  return enabledByRole ? data || false : true
}
