import { useMemo } from 'react'
import dayjs from 'dayjs'

import useIntervalResult from './useIntervalResult'

export default function useOnDemandElapsedTime(date) {
  const seconds = useIntervalResult(() => {
    return dayjs().diff(dayjs(date), 'seconds')
  })

  const formattedTime = useMemo(() => {
    const duration = dayjs.duration(seconds, 'seconds')
    return duration.format('mm:ss')
  }, [seconds])

  const color = seconds >= 540 ? 'error.main' : seconds >= 300 ? 'warning.main' : 'text.primary'

  return { seconds, formattedTime, color }
}
