import { Link as RouterLink } from 'react-router'
import { range } from 'lodash'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { RoleGuard } from '@shared/providers/src/MeProvider'
import { OnDemandStatus, UserRole } from '@shared/utils'

import useDetailsQueryParam from '@hooks/useDetailsQueryParam'
import { AppointmentIcon, MoreOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'
import OnDemandStatusChip from '@components/OnDemandStatusChip'
import CBO from '@components/TableCell/CBO'

import OnDemandDetailsModal, { useOnDemandAppointmentAccept } from '../OnDemandDetailsModal'
import { useOnDemandAppointments } from './Appointments.hooks'
import { styles, TimeElapsed } from './Appointments.utils'

export default function Appointments() {
  const [id, setId] = useDetailsQueryParam()

  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = useOnDemandAppointments()
  const accept = useOnDemandAppointmentAccept()

  const showLoading = isPending
  const showEmpty = !isPending && data?.length === 0
  const showData = !isPending && data?.length > 0

  if (showEmpty) return <Empty />

  return (
    <TableContainer>
      <OnDemandDetailsModal open={Boolean(id)} id={id} onClose={() => setId(undefined)} />
      <Table sx={styles.table}>
        <TableBody>
          {showLoading && range(5).map((i) => <Appointment.Loading key={i} />)}
          {showData && (
            <>
              {data?.map((a) => (
                <Appointment key={a.id} data={a} onClick={() => setId(a.id)} onAccept={() => accept(a.id)} />
              ))}
              {hasNextPage && (
                <TableRow sx={styles.row} onClick={() => fetchNextPage()}>
                  <TableCell colSpan={5}>
                    <Button
                      fullWidth
                      loading={isFetchingNextPage}
                      endIcon={<MoreOutlinedIcon rotate={90} />}
                      loadingPosition="end"
                      sx={{ textTransform: 'none' }}
                    >
                      more
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Appointment({ data, onClick, onAccept }) {
  return (
    <TableRow sx={styles.row} onClick={onClick}>
      <TableCell width={100}>
        <Stack spacing={-0.5}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <AppointmentIcon type="video" />
            <Typography>{data.providerType.name}</Typography>
          </Stack>
          <Typography sx={{ color: 'text.secondary' }}>{data.id}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Avatar user={data.user} hover="card" />
          <Stack spacing={-0.5}>
            <Typography>{data.user.fullName}</Typography>
            <Typography>
              MRN:
              <Link
                component={RouterLink}
                to={`/app/patients/${data.user.id}`}
                target="_blank"
                rel="noopener"
                sx={{ ml: 1 }}
                onClick={(e) => e.stopPropagation()}
              >
                {data.user.id}
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <CBO data={data.cbo} />
      </TableCell>
      <TableCell>
        <Typography sx={{ color: 'text.secondary' }}>
          Providers notified:{' '}
          <Typography component="span" sx={{ color: 'text.primary' }}>
            {data.providerIds.length}
          </Typography>
        </Typography>
      </TableCell>
      <TableCell align="right" width={150}>
        {data.status === OnDemandStatus.Pending ? <TimeElapsed date={data.createdAt} /> : <OnDemandStatusChip value={data.status} />}
      </TableCell>
      <TableCell align="right">
        <RoleGuard allowed={[UserRole.Provider]}>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              onAccept()
            }}
          >
            Accept
          </Button>
        </RoleGuard>
      </TableCell>
    </TableRow>
  )
}

Appointment.Loading = function () {
  return (
    <TableRow sx={styles.row}>
      <TableCell width={100}>
        <Stack spacing={-0.5}>
          <Typography>
            <Skeleton width={50} />
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            <Skeleton width={100} />
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Skeleton variant="rounded" width={40} height={40} />
          <Stack spacing={-0.5}>
            <Typography>
              <Skeleton width={150} />
            </Typography>
            <Typography>
              <Skeleton width={80} />
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <CBO.Loading />
      </TableCell>
      <TableCell align="right" width={150}>
        <TimeElapsed.Loading />
      </TableCell>
      <TableCell align="right" />
    </TableRow>
  )
}

function Empty() {
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        mx: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200,
        borderRadius: 4,
      }}
    >
      <Typography variant="h3" sx={{ color: 'text.secondary', textAlign: 'center' }}>
        No data to display
      </Typography>
    </Paper>
  )
}
