import { useState } from 'react'

import CardActionArea from '@mui/material/CardActionArea'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@components/_mui/Typography'

import usePubSub, { PubSubEvents } from '@shared/hooks/src/usePubSub'
import { viewBlob } from '@shared/utils'

import { styles, Title } from '../Notifications.utils'

/**
 * Handle long-running file generations
 */
export default function Files() {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(undefined)

  usePubSub(PubSubEvents.FileReady, (data) => {
    setData(data)
    setOpen(true)
  })

  const handleClick = () => {
    setOpen(false)
    viewBlob(data)
  }

  return (
    <Snackbar
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') setOpen(false)
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <CardActionArea onClick={handleClick} sx={styles.container}>
        <Title label="File is ready for review" onClose={() => setOpen(false)} />
        <Typography>Please press here to open</Typography>
      </CardActionArea>
    </Snackbar>
  )
}
