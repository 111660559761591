import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

const styles = {
  header: {
    backgroundColor: 'primary.100',
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'primary.300',
    px: 2,
    py: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 1,
  },
}

export default function Header({ isDirty, isSubmitting, onCancel }) {
  return (
    <Stack direction="row" sx={styles.header}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Button variant="outlined" color="secondary" onClick={onCancel} disabled={isSubmitting} data-testid="sop-update-cancel">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          loading={isSubmitting}
          disabled={!isDirty}
          data-testid="sop-update-submit"
        >
          Save
        </Button>
      </Stack>
    </Stack>
  )
}
