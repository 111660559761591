import { createContext, useContext, useEffect } from 'react'
import { Navigate } from 'react-router'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import useAuthenticated from '@shared/hooks/src/useAuthenticated'
import { PubNub, UserRole } from '@shared/utils'

import { honeybadger } from '../HoneybadgerProvider'
import { useDateTimeLocale } from './MeProvider.hooks'

const MeContext = createContext(undefined)

MeProvider.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
}

/**
 * Provide a context for the current user.
 */
export default function MeProvider({ value: user, children }) {
  const locale = useDateTimeLocale(user)

  useEffect(() => {
    if (!user?.id) return

    PubNub.init(user.id.toString())
    honeybadger.setContext({ user_id: user.id })

    dayjs.updateLocale('en', locale)
  }, [locale, user?.id])

  return <MeContext.Provider value={user}>{children}</MeContext.Provider>
}

export function MeMockedProvider({ value, children }) {
  return <MeContext.Provider value={value}>{children}</MeContext.Provider>
}

export const useMe = () => useContext(MeContext)

RoleGuard.propTypes = {
  /** Redirect to 401 if user doesn't have access */
  redirect: PropTypes.bool,

  /** List of allowed roles who have access */
  allowed: PropTypes.arrayOf(PropTypes.oneOf(Object.values(UserRole))),

  /** List of prohibited roles who don't have access */
  prohibited: PropTypes.arrayOf(PropTypes.oneOf(Object.values(UserRole))),

  /** Predicate function to determine access based on user */
  predicate: PropTypes.func,
}

/**
 * Checks if the user can access the children based on roles or a predicate function.
 *
 * @example: (
 *   <RoleGuard prohibited={[UserRole.Patient]}>
 *     <Dashboard />
 *   </RoleGuard>
 *
 *   <RoleGuard predicate={(user) => user.isAdmin}>
 *     <AdminPanel />
 *   </RoleGuard>
 * )
 */
export function RoleGuard({ redirect = false, allowed = [], prohibited = [], predicate, children }) {
  const me = useMe()
  const isAllowed = predicate ? predicate(me) : isUserAllowed(me, allowed, prohibited)

  if (isAllowed) return children
  if (redirect) return <Navigate replace to="/401" />
  return null
}

/**
 * Extracts the required data to detect whether it can render the children passed as props,
 * otherwise it redirects the user to the '/login' route.
 *
 * @example: (
 *   <AuthGuard>
 *     <UpdatePassword />
 *   </AuthGuard>
 * )
 *
 * @param {JSX.Element|*} children
 * @returns {JSX.Element|*} wrapped component
 */
export function AuthGuard({ children }) {
  const authenticated = useAuthenticated()

  if (!authenticated) {
    return <Navigate replace to="/" />
  }

  return children
}

/**
 * Extracts the required data to detect whether it can render the children passed as props,
 * otherwise it redirects the user to the '/' route.
 *
 * example: (
 *   <GuestGuard>
 *     <Login />
 *   </GuestGuard>
 * )
 *
 * @param {JSX.Element|*} children
 * @returns {JSX.Element|*} wrapped component
 */
export function GuestGuard({ children }) {
  const authenticated = useAuthenticated()

  if (authenticated) {
    return <Navigate replace to="/" />
  }

  return children
}

export function isUserAllowed(user, allowed = [], prohibited = []) {
  if (!user) return false

  // Handle cases where the perms are dynamically generated. So RoleGuard is in place but everyone is allowed.
  if (allowed.length === 0 && prohibited.length === 0) return true

  if (allowed.length > 0 && allowed.includes(user.role)) return true

  if (prohibited.length > 0 && !prohibited.includes(user.role)) return true

  return false
}

export function useRoleGuard() {
  const me = useMe()

  return (allowed = [], prohibited = []) => isUserAllowed(me, allowed, prohibited)
}
