import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@components/_mui/Typography'

import useDevices from '@shared/twilio/src/hooks/useDevices'
import useActiveSinkId from '@shared/twilio/src/providers/VideoProvider/useActiveSinkId'

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices()
  const [activeSinkId, setActiveSinkId] = useActiveSinkId()
  const activeOutputLabel = audioOutputDevices.find((device) => device.deviceId === activeSinkId)?.label

  return (
    <div>
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography gutterBottom sx={{ color: 'text.secondary' }}>
            Speakers
          </Typography>
          <Select onChange={(e) => setActiveSinkId(e.target.value)} value={activeSinkId} variant="outlined">
            {audioOutputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label || device.deviceId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography sx={{ color: 'text.secondary' }}>Speakers</Typography>
          <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
        </>
      )}
    </div>
  )
}
