import { useState } from 'react'

import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import usePubSub, { PubSubEvents } from '@shared/hooks/src/usePubSub'

import Avatar from '@components/Avatar'

import { styles, Title } from '../Notifications.utils'

/**
 * Handle the display of an Appointment notification.
 */
export default function OutOfOffice() {
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState([])

  const handleClose = () => setOpen(false)

  usePubSub(PubSubEvents.UserOutOfOffice, (users = []) => {
    setUsers(users)
    setOpen(true)
  })

  return (
    <Snackbar
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div>
        <Stack spacing={1} sx={styles.container}>
          <Title label="Out Of Office" onClose={handleClose} />
          <Stack spacing={2}>
            {users.map((user) => (
              <Stack key={user.id}>
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Avatar user={user} size="xs" />
                  <Typography>{user.fullName}</Typography>
                </Stack>
                <Typography sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{user.outOfOfficeMessage}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </div>
    </Snackbar>
  )
}
