import Chip from '@mui/material/Chip'

import { AppointmentStatus } from '@shared/utils'

import { CheckOutlinedIcon, ClockCircleOutlinedIcon, CloseCircleOutlinedIcon, EditOutlinedIcon, SyncOutlinedIcon } from '@icons'

export default function AppointmentStatusChip({ value }) {
  if (value === AppointmentStatus.Scheduled) {
    return <Chip icon={<CheckOutlinedIcon />} size="small" label="Scheduled" color="secondary" variant="combined" />
  }

  if (value === AppointmentStatus.Waiting) {
    return <Chip icon={<ClockCircleOutlinedIcon />} size="small" label="Waiting" color="primary" />
  }

  if (value === AppointmentStatus.InProgress) {
    return <Chip icon={<SyncOutlinedIcon />} size="small" label="In progress" color="success" variant="combined" />
  }

  if (value === AppointmentStatus.Documenting) {
    return <Chip icon={<EditOutlinedIcon />} size="small" label="Documenting" color="warning" variant="combined" />
  }

  if (value === AppointmentStatus.Complete) {
    return <Chip icon={<CheckOutlinedIcon />} size="small" label="Complete" color="secondary" variant="combined" />
  }

  if (value === AppointmentStatus.Canceled) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Canceled" color="secondary" variant="combined" />
  }

  if (value === AppointmentStatus.Missed) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Missed" color="error" variant="combined" />
  }

  if (value === AppointmentStatus.Failed) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Failed" color="error" variant="combined" />
  }

  if (value === AppointmentStatus.Rejected) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Rejected" color="error" variant="combined" />
  }

  if (value === AppointmentStatus.Abandoned) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Abandoned" color="error" variant="combined" />
  }

  // Handle cases where a status is not known. This should never happen!!!
  return <Chip size="small" label="Unknown" color="error" />
}
