import { forwardRef, useState } from 'react'

import LinkMui from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'

/**
 * Extend MUI Link with tooltip on overflow.
 */
const Link = forwardRef((props, ref) => {
  // Optimization for when we know the text will overflow
  if (props.noWrap === true) return <Ellipsis ref={ref} {...props} />

  return <LinkMui ref={ref} {...props} />
})

const Ellipsis = forwardRef(({ children, ...props }, ref) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false)

  const handleShouldShow = ({ currentTarget }) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true)
    }
  }

  return (
    <Tooltip open={tooltipEnabled} title={children} onClose={() => setTooltipEnabled(false)}>
      <LinkMui onMouseEnter={handleShouldShow} {...props}>
        {children}
      </LinkMui>
    </Tooltip>
  )
})

export default Link
