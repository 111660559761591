import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@components/_mui/Typography'

import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { getTestId } from '@shared/utils'

import { useCBO } from '@pages/CBOs/CBO/CBO.hooks'
import { CloseOutlinedIcon, SearchOutlinedIcon } from '@icons'
import SelectCBO from '@components/SelectCBO'

import { FilterPopper } from '../TableFilters.utils'

CBOSelect.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Title of the popper */
  title: PropTypes.string,

  /** Callback fired when the value changes */
  onChange: PropTypes.func.isRequired,

  /** Dayjs value */
  value: PropTypes.number,
}

/**
 * Button opens a popper to select or manually search a CBO
 *
 * @example
 * <CBOSelect label="CBO" value={value} onChange={handleChange} />
 */
export default function CBOSelect({ label, title, onChange, value, queryParams, ...other }) {
  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [cbo, setCBO] = useState(null)

  const testId = getTestId(other, `cbo-select-${label}`)

  // Reset the cbo if the initialValue changes to empty
  useEffect(() => {
    if (!value && cbo) {
      setCBO(null)
    }
  }, [value, cbo])

  const query = useCBO(value, { enabled: Boolean(value) && !cbo })

  useQueryEvents(query, {
    onSuccess: (cbo) => setCBO(cbo),
  })

  const handleToggle = () => setOpen((o) => !o)

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return
    setOpen(false)
  }

  const handleSelect = (selectedCBO) => {
    onChange(selectedCBO?.id)
    setCBO(selectedCBO)
    setOpen(false)
  }

  return (
    <>
      <Button
        ref={anchorRef}
        color="inherit"
        endIcon={value ? <CloseOutlinedIcon /> : <SearchOutlinedIcon />}
        onClick={value ? () => onChange(undefined) : handleToggle}
        data-testid={`${testId}-button`}
        {...other}
      >
        {label}
        {(cbo || query.isFetching) && (
          <Typography
            component="span"
            noWrap
            sx={{
              color: 'primary.main',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
          >
            {cbo ? `: ${cbo.name}` : <Skeleton width={80} sx={{ ml: 1 }} />}
          </Typography>
        )}
      </Button>
      <FilterPopper title={title} open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <SelectCBO
          openOnFocus
          placeholder="CBO"
          disabled={query.isFetching}
          loading={query.isFetching}
          value={cbo}
          onChange={handleSelect}
          inputProps={{ autoFocus: true }}
          queryParams={queryParams}
          data-testid={testId}
        />
      </FilterPopper>
    </>
  )
}
