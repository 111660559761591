import { isMobile } from 'react-device-detect'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { useCBO } from '@pages/CBOs/CBO/CBO.hooks'
import { bindDelayedHover } from '@components/UserCard/UserCard.utils'

import Content from './components/Content'
import Header from './components/Header'

const propTypes = {
  /** CBO id to fetch cbo data */
  cboId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CBOCard.propTypes = propTypes
CBOCardPopper.propTypes = propTypes

export default function CBOCard({ cboId }) {
  const { data, isError, isPending } = useCBO(cboId, undefined, { skipHandling: true })

  if (isPending) return <Loading />
  if (isError) return <Error />

  return (
    <Container>
      <Header cbo={data} />
      <Content cbo={data} />
    </Container>
  )
}

export function CBOCardPopper({ children, cboId }) {
  // Don't show popper if cboId is not provided
  if (!cboId) return children

  return (
    <PopupState variant="popover" popupId="cbo-card-popover">
      {(popupState) => {
        const hoverHandler = isMobile ? bindHover(popupState) : bindDelayedHover(popupState, 750)

        return (
          <div
            style={{ cursor: 'progress' }}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
          >
            <div {...hoverHandler}>{children}</div>
            <HoverPopover {...bindPopover(popupState)}>
              <CBOCard cboId={cboId} />
            </HoverPopover>
          </div>
        )
      }}
    </PopupState>
  )
}

function Loading() {
  return (
    <Container>
      <Header.Loading />
      <Content.Loading />
    </Container>
  )
}

function Error() {
  return (
    <Container>
      <Stack sx={{ height: 200, justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h4" align="center" sx={{ width: 260 }}>
          Unexpected error occurred, please try again later.
        </Typography>
      </Stack>
    </Container>
  )
}

function Container({ children }) {
  return (
    <Paper sx={{ width: 430, height: '100%' }}>
      <Stack spacing={1} sx={{ p: 2 }}>
        {children}
      </Stack>
    </Paper>
  )
}
