import { createContext, useCallback, useContext, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/styles/useTheme'

const LayoutStateContext = createContext(undefined)

export const useLayoutState = () => useContext(LayoutStateContext)

/**
 * Provider to manage the header/sidebar state of the app.
 * this will force the sidebar close on smaller screens.
 *
 * On larger screens the user can control the open/closed state and
 * it will be saved in local storage so it can be restored on page reload.
 *
 * @return {Array} [sidebarOpen, setSidebarOpen, setSidebarClosed]
 * sidebarOpen is the current state of the sidebar
 * setSidebarOpen is a function to open the sidebar
 * setSidebarClosed is a function to close the sidebar
 */
export default function LayoutStateProvider({ children }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const [sidebarState, setSidebarState] = useLocalStorage(`sidebar-open`, '')

  const setSidebarOpen = useCallback(() => {
    setSidebarState('open')
  }, [setSidebarState])

  const setSidebarClosed = useCallback(() => {
    setSidebarState('closed')
  }, [setSidebarState])

  const sidebarOpen = useMemo(() => {
    if (isSmallScreen && !sidebarState) {
      // If app is loaded on a small screen, force the sidebar closed
      return false
    }

    if (!sidebarState) return true

    return sidebarState === 'open'
  }, [isSmallScreen, sidebarState])

  return <LayoutStateContext.Provider value={[sidebarOpen, setSidebarOpen, setSidebarClosed]}>{children}</LayoutStateContext.Provider>
}
