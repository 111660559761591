import { useState } from 'react'
import { Link as RouterLink } from 'react-router'
import { useDebounce } from 'usehooks-ts'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import DialogTitle from '@components/_mui/DialogTitle'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import { UserRole } from '@shared/utils'

import { SearchOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'
import { useUsers } from '@components/SelectUser/SelectUser.hooks'
import TableFilters, { FilterType } from '@components/TableFilters'
import Tip from '@components/Tip'

export default function Search() {
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  return (
    <Box id="header-patient-search" sx={{ ml: 1 }}>
      <IconButton
        id="search-button"
        onClick={() => setOpen(true)}
        color="secondary"
        aria-label="Search"
        data-testid="header-search"
        size={matchDownSm ? 'small' : 'medium'}
        sx={{ color: 'text.primary' }}
      >
        <SearchOutlinedIcon style={{ fontSize: 23 }} />
      </IconButton>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Search</DialogTitle>
        <DialogContent>
          <Content onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

function Content({ onClose }) {
  const [value, setValue] = useState('')
  const [dob, setDOB] = useState('')

  const search = useDebounce(value.trim(), 500)
  const enabled = search.length > 0 || Boolean(dob)

  const { data, isPending } = useUsers(
    { search, role: UserRole.Patient, dob, limit: 5 },
    {
      enabled,
      staleTime: 60 * 1000,
      keepPreviousData: true,
    }
  )

  const showLoading = isPending && enabled
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  return (
    <Stack sx={{ gap: 3 }}>
      <Tip>Search for a patient by their name, email, or MRN number.</Tip>
      <Stack>
        <TableFilters
          schema={[{ key: 'dob', label: 'DOB', type: FilterType.DATE }]}
          values={{ dob }}
          onChange={({ dob }) => setDOB(dob)}
          showReset={Boolean(dob)}
          onReset={() => setDOB('')}
          sx={{ p: 1 }}
        />
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          placeholder="Search for a patient"
          autoComplete="off"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          slotProps={{
            htmlInput: { 'data-testid': `search-input` },
          }}
        />
      </Stack>
      {showLoading && (
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', height: 300 }}>
          <CircularProgress />
        </Stack>
      )}
      {showEmpty && (
        <Stack spacing={1} data-testid="patient-results-empty">
          <Typography align="center" sx={{ color: 'text.secondary' }}>
            We couldn’t find any patients that match. Try searching with a different term.
          </Typography>
        </Stack>
      )}
      {showData && (
        <Stack sx={{ gap: 1 }}>
          <Typography variant="h4">Patients</Typography>
          <List
            disablePadding
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              overflow: 'hidden',
            }}
            data-testid="patient-results-list"
          >
            {data.map((patient) => (
              <ListItemButton divider key={patient.id} component={RouterLink} to={`/app/patients/${patient.id}`} onClick={onClose}>
                <ListItemAvatar>
                  <Avatar user={patient} />
                </ListItemAvatar>
                <ListItemText primary={patient.fullName} secondary={`MRN ${patient.id}`} />
              </ListItemButton>
            ))}
          </List>
        </Stack>
      )}
    </Stack>
  )
}
