import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import useOnDemandElapsedTime from '@shared/hooks/src/useOnDemandElapsedTime'

export const styles = {
  table: {
    px: 2,
    borderCollapse: 'separate',
    borderSpacing: (theme) => `0 ${theme.spacing(2)}`,
  },
  row: (theme) => ({
    cursor: 'pointer',
    bgcolor: 'background.paper',
    borderRadius: theme.spacing(2),
    boxShadow: 0,
    transition: 'box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': { boxShadow: theme.customShadows.z2 },
    '& .MuiTableCell-root': {
      borderBottom: '1px solid',
      borderTop: '1px solid',
      borderColor: 'divider',
      '&:first-of-type': {
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
        borderLeft: '1px solid',
        borderColor: 'divider',
      },
      '&:last-of-type': {
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        borderRight: '1px solid',
        borderColor: 'divider',
      },
    },
  }),
}

export function TimeElapsed({ date }) {
  const { formattedTime, color } = useOnDemandElapsedTime(date)

  return (
    <Stack spacing={-0.5}>
      <Typography sx={{ color: 'text.secondary' }}>Time Elapsed</Typography>
      <Typography variant="h3" sx={{ color, fontWeight: 'normal' }}>
        {formattedTime}
      </Typography>
    </Stack>
  )
}

TimeElapsed.Loading = function () {
  return (
    <Stack spacing={-0.5}>
      <Typography>
        <Skeleton width={100} />
      </Typography>
      <Typography variant="h3">
        <Skeleton width={100} />
      </Typography>
    </Stack>
  )
}
