import { Link as RouterLink } from 'react-router'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import Avatar from '@components/Avatar'

import Property from './Property'

export default function PatientProperty({ label, user }) {
  if (!user) return <Property label={label}>—</Property>

  return (
    <Property label={label}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Avatar user={user} hover="card" />
        <Stack spacing={-0.5}>
          <Typography>{user.fullName}</Typography>
          <Stack direction="row" spacing={1}>
            <Link component={RouterLink} to={`/app/patients/${user.id}`} target="_blank">
              {user.id}
            </Link>
            <Typography sx={{ color: 'text.secondary' }}>{user.pronouns}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Property>
  )
}

PatientProperty.Loading = function ({ label }) {
  return (
    <Property label={label}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Skeleton variant="rounded" width={40} height={40} />
        <Stack spacing={-0.5}>
          <Typography>
            <Skeleton width={150} />
          </Typography>
          <Typography>
            <Skeleton width={100} />
          </Typography>
        </Stack>
      </Stack>
    </Property>
  )
}
