import { useMemo } from 'react'

import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@components/_mui/IconButton'

import usePopover from '@shared/hooks/src/usePopover'

import { MentionsPopover } from '@pages/Mentions'
import { useMentions } from '@pages/Mentions/Mentions.hooks'
import { EmailIcon } from '@components/_icons'

export default function Mentions() {
  const popover = usePopover()
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { data: mentionsCache } = useMentions({ type: 'received', unreplied: true }, { enabled: false })

  const hasUnreplied = useMemo(() => {
    return (mentionsCache || []).some((mention) => !mention.repliedAt)
  }, [mentionsCache])

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        id="mentions-button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        color="secondary"
        aria-label="Mentions"
        data-testid="header-mentions"
        size={matchDownSm ? 'small' : 'medium'}
        sx={{ color: 'text.primary' }}
      >
        <Badge color="error" variant="dot" invisible={!hasUnreplied} data-testid="header-mentions-badge" data-test-unread={hasUnreplied}>
          <EmailIcon style={{ fontSize: 20 }} />
        </Badge>
      </IconButton>
      <MentionsPopover anchorEl={popover.anchorRef.current} open={popover.open} onClose={popover.handleClose} />
    </Box>
  )
}
