import { isMobile } from 'react-device-detect'
import dayjs from 'dayjs'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import Image from '@shared/components/src/Image'
import { viewBase64 } from '@shared/utils'

import Overlay from '@components/Overlay'
import StatusChip from '@components/StatusChip'
import { bindDelayedHover } from '@components/UserCard/UserCard.utils'

import { useInsurance } from './InsuranceCard.hooks'

const propTypes = {
  /** Patient id to fetch the insurance data */
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /** Insurance id to fetch the insurance data */
  insuranceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

InsuranceCard.propTypes = propTypes
InsuranceCardPopper.propTypes = propTypes

export default function InsuranceCard({ patientId, insuranceId }) {
  const { data, isError, isPending } = useInsurance(patientId, insuranceId)

  if (isPending) return <Loading />
  if (isError) return <Error />

  return (
    <Container>
      <Stack>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h4">{data.company}</Typography>
          {data.validatedByAvita && <StatusChip icon label="Verified" type="success" />}
        </Stack>
        <Typography>{data.subscriber}</Typography>
      </Stack>
      <Property label="ID">
        <Typography copy>{data.idnum}</Typography>
      </Property>
      <Property label="GRP Num">
        <Typography copy>{data.groupnum}</Typography>
      </Property>
      <Property label="Rx BIN">
        <Typography copy>{data.bin}</Typography>
      </Property>
      <Property label="Rx PCN">
        <Typography copy>{data.pcn}</Typography>
      </Property>
      <Property label="Date Added">
        <Typography>{data.created ? dayjs(data.created).format('L') : ''}</Typography>
      </Property>
      <Property label="Front">
        <ImageOverlay src={data.front} alt="front of insurance card" />
      </Property>
      <Property label="Back">
        <ImageOverlay src={data.back} alt="back of insurance card" />
      </Property>
    </Container>
  )
}

export function InsuranceCardPopper({ children, patientId, insuranceId }) {
  // Don't show popper if patientId or insuranceId is not defined
  if (!patientId || !insuranceId) return children

  return (
    <PopupState variant="popover" popupId="insurance-card-popover">
      {(popupState) => {
        const hoverHandler = isMobile ? bindHover(popupState) : bindDelayedHover(popupState, 750)

        return (
          <div
            style={{ cursor: 'progress' }}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
          >
            <div {...hoverHandler}>{children}</div>
            <HoverPopover {...bindPopover(popupState)}>
              <InsuranceCard patientId={patientId} insuranceId={insuranceId} />
            </HoverPopover>
          </div>
        )
      }}
    </PopupState>
  )
}

function Loading() {
  return (
    <Container>
      <Stack>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h4">
            <Skeleton width={200} />
          </Typography>
        </Stack>
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Stack>
      <Property label="ID">
        <Typography>
          <Skeleton width={200} />
        </Typography>
      </Property>
      <Property label="GRP Num">
        <Typography>
          <Skeleton width={150} />
        </Typography>
      </Property>
      <Property label="Rx BIN">
        <Typography>
          <Skeleton width={120} />
        </Typography>
      </Property>
      <Property label="Rx PCN">
        <Typography>
          <Skeleton width={150} />
        </Typography>
      </Property>
      <Property label="Date Added">
        <Typography>
          <Skeleton width={120} />
        </Typography>
      </Property>
      <Property label="Front">
        <Skeleton variant="rounded" width={300} height={200} />
      </Property>
      <Property label="Back">
        <Skeleton variant="rounded" width={300} height={200} />
      </Property>
    </Container>
  )
}

function Error() {
  return (
    <Container>
      <Stack sx={{ height: 200, justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h4" align="center" sx={{ width: 260 }}>
          Unexpected error occurred, please try again later.
        </Typography>
      </Stack>
    </Container>
  )
}

function Container({ children }) {
  return (
    <Paper sx={{ width: 600, height: '100%' }}>
      <Stack spacing={2} sx={{ p: 2 }}>
        {children}
      </Stack>
    </Paper>
  )
}

function Property({ label, children }) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography sx={{ minWidth: 100 }}>{label}</Typography>
      {children}
    </Stack>
  )
}

function ImageOverlay({ src, ...rest }) {
  if (!src) return <Typography sx={{ color: 'text.secondary' }}>Image not available</Typography>

  return (
    <Overlay.Download onClick={() => viewBase64(src, 'image/jpeg')}>
      <Image
        src={`data:image/*;base64,${src}`}
        fit="contain"
        duration={500}
        width="unset"
        sx={{ maxWidth: 300, maxHeight: 200, minHeight: 100 }}
        {...rest}
      />
    </Overlay.Download>
  )
}
