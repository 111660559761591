import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

const styles = {
  container: {
    alignItems: 'center',
    backgroundColor: '#f6f8fa',
    height: (theme) => theme.mixins.footer.height,
    borderTop: '1px solid rgba(0,0,0,0.07)',
    color: '#7a878e',
    fontSize: 13,
    fontWeight: 'normal',
    pt: '6px',
    pl: '15px',
  },
}

export default function Footer() {
  return (
    <Stack direction="row" sx={styles.container}>
      <Typography>© {new Date().getFullYear()} QCare Plus</Typography>
    </Stack>
  )
}
