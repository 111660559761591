import { useEffect } from 'react'
import { useBlocker } from 'react-router'

/**
 * Prompt the user with a message when they try to navigate away from the page.
 *
 * @param {string} message - string that displayed sometimes... depending on type of page exit.
 * @param {boolean} when - whether to prompt the user or not.
 * @param {boolean} allowInApp - whether to allow the user to navigate within the app without a prompt
 */
export default function usePrompt(message, when = true, allowInApp = false) {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (!when || allowInApp || currentLocation.pathname === nextLocation.pathname) return false
    return message
  })

  useEffect(() => {
    if (when) window.onbeforeunload = () => message

    return () => {
      window.onbeforeunload = null
    }
  }, [message, when])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (window.confirm(message)) blocker.proceed()
      else blocker.reset()
    }
  }, [blocker, message])
}
