import { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import IconButton from '@components/_mui/IconButton'

import {
  BoldOutlined,
  CalendarOutlinedIcon,
  DownOutlinedIcon,
  ItalicOutlined,
  LinkOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@icons'

import SelectorDialog from './SelectorDialog'

export default function RichTextEditorToolbar({ editor, selectorEnabled = false, dateEnabled = false }) {
  const isMentioningEnabled = editor.extensionManager.extensions.find((ext) => ext.name === 'mention')
  const isQPhraseEnabled = editor.extensionManager.extensions.find((ext) => ext.name === 'q-phrases')

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5, gap: 0.5 }}>
      <Icon
        title="Bold (Ctrl+B)"
        isActive={editor.isActive('bold')}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <BoldOutlined />
      </Icon>
      <Icon
        title="Italic (Ctrl+I)"
        isActive={editor.isActive('italic')}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <ItalicOutlined />
      </Icon>
      <Icon
        title="Underline (Ctrl+U)"
        isActive={editor.isActive('underline')}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <UnderlineOutlined />
      </Icon>
      <VR />
      <LinkButton editor={editor} />
      <VR />
      <Icon
        title="Ordered List"
        isActive={editor.isActive('orderedList')}
        disabled={!editor.can().chain().focus().toggleOrderedList().run()}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <OrderedListOutlined />
      </Icon>
      <Icon
        title="Bullet List"
        isActive={editor.isActive('bulletList')}
        disabled={!editor.can().chain().focus().toggleBulletList().run()}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <UnorderedListOutlined />
      </Icon>
      {(isQPhraseEnabled || isMentioningEnabled || selectorEnabled || dateEnabled) && <VR />}
      {isQPhraseEnabled && (
        <Icon
          title="Q Phrase"
          disabled={!editor.can().chain().focus().insertContent('/').run()}
          onClick={() => editor.chain().focus().insertContent('/').run()}
        >
          /
        </Icon>
      )}
      {isMentioningEnabled && (
        <Icon
          title="Mention User"
          isActive={editor.isActive('mention')}
          disabled={!editor.can().chain().focus().insertContent('@').run()}
          onClick={() => editor.chain().focus().insertContent('@').run()}
        >
          @
        </Icon>
      )}
      {selectorEnabled && <SelectorButton editor={editor} />}
      {dateEnabled && <DateButton editor={editor} />}
    </Box>
  )
}

function SelectorButton({ editor }) {
  const [selectorDialogOpen, setSelectorDialogOpen] = useState(false)

  const handleSelectorButtonClick = () => {
    setSelectorDialogOpen(true)
  }

  const handleSelectorDialogClose = () => {
    setSelectorDialogOpen(false)
  }

  const handleInsertSelector = (options) => {
    editor.chain().focus().insertSelect(options).run()
  }

  return (
    <>
      <Icon title="Insert Selector" isActive={editor.isActive('selector')} onClick={handleSelectorButtonClick}>
        <DownOutlinedIcon />
      </Icon>
      <SelectorDialog open={selectorDialogOpen} onClose={handleSelectorDialogClose} initialOptions={['']} onSubmit={handleInsertSelector} />
    </>
  )
}

function DateButton({ editor }) {
  return (
    <Icon title="Insert Date" onClick={() => editor.chain().focus().insertDate().run()}>
      <CalendarOutlinedIcon />
    </Icon>
  )
}

function LinkButton({ editor }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [linkUrl, setLinkUrl] = useState('')

  const handleLinkClick = (event) => {
    const { href } = editor.getAttributes('link')
    setLinkUrl(href || '')
    setAnchorEl(event.currentTarget)
  }

  const handleLinkConfirm = () => {
    if (linkUrl) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: linkUrl }).run()
    } else {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    }
    setAnchorEl(null)
    setLinkUrl('')
  }

  const handleRemoveLink = () => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run()
    setAnchorEl(null)
    setLinkUrl('')
  }

  return (
    <>
      <Icon title="Add or Edit Link" isActive={editor.isActive('link')} onClick={handleLinkClick}>
        <LinkOutlined />
      </Icon>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
          setLinkUrl('')
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box sx={{ p: 2, width: 400 }}>
          <TextField
            fullWidth
            autoFocus
            label="Link URL"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleLinkConfirm()
              }
            }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            {editor.isActive('link') && (
              <Button color="secondary" onClick={handleRemoveLink}>
                Remove Link
              </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              onClick={() => {
                setAnchorEl(null)
                setLinkUrl('')
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleLinkConfirm}>
              OK
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

const VR = () => <Divider orientation="vertical" sx={{ height: 24 }} />
const Icon = ({ children, isActive = false, ...rest }) => (
  <IconButton color={isActive ? 'primary' : 'secondary'} variant={isActive ? 'shadow' : 'text'} {...rest}>
    {children}
  </IconButton>
)
