import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import Avatar from '@components/Avatar'

import Property from './Property'

export default function ProviderProperty({ label, user }) {
  if (!user) return <Property label={label}>—</Property>

  return (
    <Property label={label}>
      <ProviderPropertyContent user={user} />
    </Property>
  )
}

ProviderProperty.Loading = function ({ label }) {
  return (
    <Property label={label}>
      <ProviderPropertyContent.Loading />
    </Property>
  )
}

export function ProviderPropertyContent({ user }) {
  if (!user) return null

  const subtitle = [user.city, user.state].filter(Boolean).join(', ')

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Avatar user={user} hover="card" />
      <Stack spacing={-0.5}>
        <Typography>{user.fullName}</Typography>
        {subtitle && <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>}
      </Stack>
    </Stack>
  )
}

ProviderPropertyContent.Loading = function () {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Skeleton variant="rounded" width={40} height={40} />
      <Stack spacing={-0.5}>
        <Typography>
          <Skeleton width={150} />
        </Typography>
        <Typography>
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </Stack>
  )
}
