import { useMemo } from 'react'
import { Link as RouterLink, useLocation } from 'react-router'

import BreadcrumbsMui from '@mui/material/Breadcrumbs'
import useMediaQuery from '@mui/material/useMediaQuery'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { toTitleCase } from '@shared/utils'

import { LeftOutlinedIcon } from '@icons'

const NO_LINK_ROUTES = ['encounters']
const HIDE_ROUTES = ['former']
const SKIP_PARENT_ROUTES = ['admin']

// TODO: This is a temporary solution until we do that through the routing
export default function Breadcrumbs({ content = null, hideLinksByIndex = [] }) {
  const location = useLocation()
  const isTooSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const breadcrumbs = useMemo(() => {
    const paths = location.pathname
      .split('/')
      .slice(2, -1)
      .filter((path) => !HIDE_ROUTES.includes(path))

    // Skip generating breadcrumbs for some root routes
    if (SKIP_PARENT_ROUTES.includes(paths[0])) return []

    return paths.map((route, index, routes) => {
      // Key numbers as numbers
      const name = /^\d+$/.test(route) ? route : toTitleCase(route)
      const fullPath = `/app/${routes.slice(0, index + 1).join('/')}`

      const hideLink = hideLinksByIndex.includes(index) || NO_LINK_ROUTES.includes(route)

      return {
        name,
        ...(hideLink ? { type: 'text' } : { to: fullPath }),
        'data-testid': `breadcrumb=${fullPath}`,
      }
    })
  }, [hideLinksByIndex, location.pathname])

  if (!breadcrumbs.length) return content
  if (isTooSmall) return content

  return (
    <BreadcrumbsMui aria-label="breadcrumb" separator={<LeftOutlinedIcon />} sx={{ pr: 2, color: 'text.primary' }}>
      <CustomLink component={RouterLink} to="/app">
        Home
      </CustomLink>
      {breadcrumbs.map(({ name, ...props }) => (
        <CustomLink key={name} {...props}>
          {name}
        </CustomLink>
      ))}
      {content}
    </BreadcrumbsMui>
  )
}

function CustomLink({ type = 'link', ...args }) {
  if (type !== 'link')
    return <Typography variant="h3" {...args} sx={[{ fontWeight: 'medium' }, ...(Array.isArray(args.sx) ? args.sx : [args.sx])]} />
  return <Link variant="h3" sx={{ textDecoration: 'none', fontWeight: 'medium' }} component={RouterLink} {...args} />
}
