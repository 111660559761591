import { Suspense } from 'react'

import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 2001,
  width: '100%',
})

export default function Loadable() {
  return (
    <LoaderWrapper>
      <LinearProgress color="primary" sx={{ borderRadius: 0 }} />
    </LoaderWrapper>
  )
}

export const LoadableSuspense = (Component) => (props) => {
  return (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  )
}
