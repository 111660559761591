import Grid from '@mui/material/Grid2'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'

import HookInputControl from '@components/HookInputControl'

export default function Title({ isSubmitting }) {
  const policies = useLookup(Lookup.SOPPolicies)
  const categories = useLookup(Lookup.SOPCategories)

  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Grid container spacing={2}>
        <Grid size={12}>
          <HookInputControl field="title">
            <TextField required disabled={isSubmitting} />
          </HookInputControl>
        </Grid>
        <Grid size={4}>
          <HookInputControl field="policy" disableOptionalLabel>
            <TextField select label="Policy Section" disabled={isSubmitting}>
              {policies.map((label) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </HookInputControl>
        </Grid>
        <Grid size={4}>
          <HookInputControl field="categories" disableOptionalLabel>
            <TextField
              label="Category"
              select
              disabled={isSubmitting}
              slotProps={{
                select: { multiple: true },
              }}
            >
              {categories.map((label) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </HookInputControl>
        </Grid>
        <Grid size={4}>
          <HookInputControl field="version">
            <TextField required disabled={isSubmitting} />
          </HookInputControl>
        </Grid>
      </Grid>
    </Paper>
  )
}
