import { useMemo } from 'react'

import { isDynamicLoadError } from '@shared/utils'

import ErrorBase from '@components/ErrorBase'

import AppUpdated from './AppUpdated'

export default function Error({ code = 500, error, resetErrorBoundary }) {
  const details = useMemo(() => {
    if (typeof error === 'string') return error
    return error?.message
  }, [error])

  if (isDynamicLoadError(error)) return <AppUpdated />

  return (
    <ErrorBase
      title="Something isn’t working"
      subtitle="That didn’t work, but we are looking into the problem. Please try again later."
      code={code}
      details={details}
      reset={resetErrorBoundary}
    />
  )
}
