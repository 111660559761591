import PropTypes from 'prop-types'

import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import { CopyOutlinedIcon } from '@icons'
import CopyToClipboard from '@components/CopyToClipboard'

Property.propTypes = {
  /** The name of the property */
  label: PropTypes.string.isRequired,

  /** The value of the property */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),

  /** Can allow copying value to clipboard */
  clipboard: PropTypes.bool,

  /** Can display stacked or in a horizontal row */
  direction: PropTypes.oneOf(['row', 'column']),

  /** Node to display after label */
  endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),

  /** Can provider a fixed with for the label */
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Can control the alignment of the items in the property */
  align: PropTypes.string,

  /** Can control the wrapping of the value */
  noWrap: PropTypes.bool,

  /** Callback fired when the property is clicked */
  onClick: PropTypes.func,
}

/**
 * Displays a property with a label and value. Can optionally allow copying the value to the clipboard.
 */
export default function Property({
  label,
  value,
  clipboard = false,
  noWrap = false,
  direction = 'column',
  endAdornment,
  labelWidth = null,
  align = 'center',
  onClick,
  ...rest
}) {
  if (clipboard) {
    return (
      <CopyToClipboard>
        {({ copy }) => (
          <PropertyDisplay
            label={label}
            value={value}
            direction={direction}
            endAdornment={<CopyIcon />}
            onClick={() => copy(value)}
            labelWidth={labelWidth}
            align={align}
            noWrap={noWrap}
            {...rest}
          />
        )}
      </CopyToClipboard>
    )
  }

  return (
    <PropertyDisplay
      label={label}
      value={value}
      direction={direction}
      endAdornment={endAdornment}
      onClick={onClick}
      labelWidth={labelWidth}
      align={align}
      noWrap={noWrap}
      {...rest}
    />
  )
}

/**
 * Renders the property display
 */
function PropertyDisplay({
  label,
  value,
  clipboard = false,
  direction,
  endAdornment,
  onClick,
  labelWidth,
  align,
  noWrap = false,
  ...rest
}) {
  const showEmpty = !value
  const showTypography = !showEmpty && ['string', 'number'].includes(typeof value)
  const showNode = !showTypography

  return (
    <Stack
      onClick={onClick}
      direction={direction}
      sx={{ cursor: onClick ? 'pointer' : 'initial', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
      {...rest}
    >
      <Stack direction="row" spacing={0.5} sx={{ alignItems: align }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            color: 'text.secondary',
            width: labelWidth || 'initial',
          }}
        >
          {label}
        </Typography>
        {endAdornment}
      </Stack>
      {showEmpty && (
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'medium',
            ml: direction === 'row' ? 1 : 0,
          }}
        >
          -
        </Typography>
      )}
      {showTypography && (
        <Typography noWrap={noWrap} variant="h5" sx={{ wordBreak: 'break-word', fontWeight: 'medium', ml: direction === 'row' ? 1 : 0 }}>
          {value}
        </Typography>
      )}
      {showNode && value}
    </Stack>
  )
}

const CopyIcon = styled(CopyOutlinedIcon)(({ theme }) => ({ color: theme.palette.text.secondary }))
