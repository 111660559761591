import { useState } from 'react'
import { mergeAttributes, Node } from '@tiptap/core'
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'

import NativeSelect from '@mui/material/NativeSelect'
import IconButton from '@components/_mui/IconButton'

import { EditOutlinedIcon } from '@icons'

import SelectorDialog from './SelectorDialog'

const SelectNodeView = ({ node, updateAttributes, editor, extension }) => {
  const { options, selected } = node.attrs
  const isEditable = editor.isEditable
  const editable = extension.options.editable

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleChange = (event) => updateAttributes({ selected: event.target.value })

  const handleEditButtonClick = () => setIsDialogOpen(true)
  const handleDialogClose = () => setIsDialogOpen(false)

  const handleUpdateSelectorOptions = (newOptions) => {
    updateAttributes({ options: newOptions, selected: newOptions[0] || '' })
    setIsDialogOpen(false)
  }

  return (
    <NodeViewWrapper contentEditable={false} style={{ display: 'inline-flex', alignItems: 'center' }}>
      <NativeSelect
        value={selected}
        onChange={handleChange}
        disabled={!isEditable || node.attrs.disabled || false}
        inputProps={{
          sx: {
            p: 0,
            maxWidth: 150,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      >
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </NativeSelect>
      {editable && (
        <>
          <IconButton size="small" onClick={handleEditButtonClick} sx={{ height: 25, width: 25 }}>
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
          <SelectorDialog open={isDialogOpen} onClose={handleDialogClose} initialOptions={options} onSubmit={handleUpdateSelectorOptions} />
        </>
      )}
    </NodeViewWrapper>
  )
}

export const SelectExtension = Node.create({
  name: 'select',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addOptions() {
    return {
      editable: false,
    }
  },

  addAttributes() {
    return {
      options: {
        default: [],
        parseHTML: (element) => JSON.parse(element.getAttribute('data-options') || '[]'),
        renderHTML: (attributes) => ({
          'data-options': JSON.stringify(attributes.options),
          contenteditable: 'false',
        }),
      },
      selected: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-selected') || '',
        renderHTML: (attributes) => ({
          'data-selected': attributes.selected,
        }),
      },
      disabled: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-disabled') === 'true',
        renderHTML: (attributes) => ({
          'data-disabled': attributes.disabled,
        }),
      },
    }
  },

  parseHTML() {
    return [{ tag: 'span[data-select]' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, { 'data-select': '' }), `Select: ${HTMLAttributes.selected || 'None'}`]
  },

  addNodeView() {
    return ReactNodeViewRenderer(SelectNodeView)
  },

  addCommands() {
    return {
      insertSelect: (options) => {
        return ({ commands }) => {
          commands.insertContent({ type: this.name, attrs: { options, selected: options[0] || '' } })
          return commands.insertContent(' ')
        }
      },
    }
  },
})

export default SelectExtension
