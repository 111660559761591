import { useCallback, useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import { useEffectOnce, useInterval, useLocalStorage } from 'usehooks-ts'

import API from '@shared/services/src/API'
import { handleErrorSilently, Logger } from '@shared/utils'

const log = Logger('KeepSessionAlive')

// Read from localStorage or default to 300 seconds
const INACTIVITY_TIMEOUT = (localStorage.getItem('keep-alive-extension-seconds') || 300) * 1000

/**
 * Wrap the keep-alive call in a debounced function.
 * Any rapid repeated calls to `handleKeepAlive()` within DEBOUNCE_WAIT
 * (e.g., 1 second) will result in only one call actually firing.
 */
const DEBOUNCE_WAIT = 1000

// List of events to listen for user activity
const activityEvents = ['mousemove', 'mousedown', 'keydown', 'wheel', 'touchmove', 'pointermove', 'visibilitychange']

/**
 * Handles the user auth session and keeps it alive if required.
 */
export default function KeepSessionAlive() {
  const keepAlive = useKeepAlive()

  // Call the prolongate session endpoint when mounted
  useEffectOnce(() => {
    keepAlive()
  })

  // Call the prolongate session endpoint every interval
  useInterval(() => {
    keepAlive()
  }, INACTIVITY_TIMEOUT)

  return null
}

/**
 * TrackUserActivity
 * - After INACTIVITY_TIMEOUT has passed since last keep-alive,
 *   attach event listeners for user actions
 * - On user action, call debounced keepAlive with the event name
 */
export function TrackUserActivity() {
  const [lastExtensionTime, setLastExtensionTime] = useLocalStorage('last-extension-time', Date.now())
  const keepAlive = useKeepAlive()

  /**
   * Debounced function to handle keepAlive calls.
   * Accepts the event name to include in the log.
   */
  const debouncedKeepAlive = useCallback(
    debounce((eventName) => {
      keepAlive()
        .then(() => {
          setLastExtensionTime(Date.now())
          log.info(
            `Extend user session - activity detected from "${eventName}". ${(
              INACTIVITY_TIMEOUT / 1000
            ).toFixed()} seconds passed since last extension`
          )
        })
        .catch((error) => {
          // Optionally handle errors specific to debounced calls
          log.error(`Failed to extend session on "${eventName}":`, error)
        })
    }, DEBOUNCE_WAIT),
    [keepAlive, setLastExtensionTime]
  )

  useEffect(() => {
    let timeoutId = null

    /**
     * Event handler for user activity.
     * Passes the event name to the debouncedKeepAlive function.
     */
    const handleUserActivity = (event) => {
      const eventName = event.type

      // Remove all event listeners to ensure this fires only once per timeout
      activityEvents.forEach((evt) => document.removeEventListener(evt, handleUserActivity))

      // Call the debounced keepAlive with the specific event name
      debouncedKeepAlive(eventName)
    }

    // Calculate the remaining time until the next inactivity period
    const timeSinceLast = Date.now() - lastExtensionTime
    const remainingTime = INACTIVITY_TIMEOUT - timeSinceLast

    // After 'remainingTime' has passed, attach event listeners
    timeoutId = setTimeout(() => {
      activityEvents.forEach((evt) => {
        document.addEventListener(evt, handleUserActivity, { once: true })
      })
    }, remainingTime)

    // Cleanup on unmount or when dependencies change
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
      activityEvents.forEach((evt) => document.removeEventListener(evt, handleUserActivity))
      // Cancel any pending debounced calls to prevent memory leaks
      debouncedKeepAlive.cancel()
    }
  }, [debouncedKeepAlive, lastExtensionTime])

  return null
}

const useKeepAlive = () => {
  const keepAlive = useMutation({
    mutationFn: () => API.heartbeat.keep(),
  })

  return useCallback(() => {
    return keepAlive.mutateAsync().catch(handleErrorSilently)
  }, [keepAlive])
}
