import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

import API from '@shared/services/src/API'
import { isJsonString, QK } from '@shared/utils'

export default function useUserSetting(user, setting) {
  return useMemo(() => getUserSetting(user, setting), [setting, user])
}

export function useMyUserSettingUpdate(me) {
  return useMutation({
    mutationFn: ({ settingId, value }) => API.users.id(me.id).settings.id(settingId).update({ value }),
    onSuccess: (updated) => {
      window?.queryClient.setQueryData(QK.me(me.id).details, (old) => {
        if (!old) return old
        const userSettings = old.userSettings.map((s) => (s.id === updated.id ? updated : s))
        return { ...old, userSettings }
      })
    },
  })
}

export function getUserSetting(user, setting) {
  if (!user) return undefined

  const userSettings = user.userSettings || []
  const userSetting = userSettings.find((userSetting) => userSetting.name === setting)

  return mapUserSetting(userSetting)
}

export function mapUserSetting(userSetting) {
  if (!userSetting) return undefined

  const valueIsBoolean = userSetting.value === 'true' || userSetting.value === 'false'

  if (valueIsBoolean) {
    let value
    if (userSetting.value === 'true') value = true
    if (userSetting.value === 'false') value = false
    return { ...userSetting, value }
  }

  const valueIsJson = isJsonString(userSetting.value)

  if (valueIsJson) {
    return { ...userSetting, value: JSON.parse(userSetting.value) }
  }

  return userSetting
}
