import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

export default function Title({ data }) {
  const testId = `sop-${data.title?.substring(0, 10)}`

  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Stack>
        <Typography variant="h3" data-testid={`${testId}-title`}>
          {data.title}
        </Typography>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Typography data-testid={`${testId}-policy`}>
            Policy Section <strong>{data.policy}</strong>
          </Typography>
          <Typography data-testid={`${testId}-category`}>
            Category <strong>{data.categories?.join(', ') || 'n/a'}</strong>
          </Typography>
          <Typography data-testid={`${testId}-version`}>
            Version <strong>{data.version || 'n/a'}</strong>
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  )
}

Title.Loading = function Loading() {
  return (
    <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
      <Stack>
        <Typography variant="h3">
          <Skeleton width={200} />
        </Typography>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography>Policy Section</Typography>
            <Skeleton width={80} />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography>Category</Typography>
            <Skeleton width={80} />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography>Version</Typography>
            <Skeleton width={80} />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}
