import { useMemo } from 'react'

import AppBar from '@mui/material/AppBar'
import { styled, useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@components/_mui/IconButton'

import { useLayoutState } from '@providers/LayoutStateProvider'
import { MenuFoldOutlinedIcon, MenuUnfoldOutlinedIcon } from '@icons'

import Content from './Content'

const styles = {
  appBar: {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    },
  },
}

export default function Header() {
  const [sidebarOpen, setSidebarOpen, setSidebarClosed] = useLayoutState()

  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  const headerContent = useMemo(() => <Content />, [])

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300'
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100'

  const mainHeader = (
    <Toolbar>
      <IconButton
        onClick={sidebarOpen ? setSidebarClosed : setSidebarOpen}
        edge="start"
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: sidebarOpen ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
        data-testid="sidebar-toggle"
      >
        {!sidebarOpen ? <MenuUnfoldOutlinedIcon /> : <MenuFoldOutlinedIcon />}
      </IconButton>
      {headerContent}
    </Toolbar>
  )

  if (matchDownLG) {
    return <AppBar {...styles.appBar}>{mainHeader}</AppBar>
  }

  return (
    <AppBarStyled open={sidebarOpen} {...styles.appBar}>
      {mainHeader}
    </AppBarStyled>
  )
}

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 2,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(!open && {
    width: `calc(100% - ${theme.spacing(7.5)})`,
  }),
  ...(open && {
    marginLeft: theme.mixins.sidebar.fullWidth,
    width: `calc(100% - ${theme.mixins.sidebar.fullWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))
