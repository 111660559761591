import { useState } from 'react'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import DialogTitle from '@components/_mui/DialogTitle'

import { PlusOutlinedIcon } from '@icons'
import Tile from '@components/Tile'

import AvailableItems from '../AvailableItems'
import { useAvailabilityCreate } from './Range.hooks'

export default function Range() {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)

  return (
    <Tile sx={{ p: 2, alignItems: 'center', justifyContent: 'center' }}>
      <Button variant="contained" startIcon={<PlusOutlinedIcon />} onClick={() => setOpen(true)}>
        Add by range of dates
      </Button>
      <Dialog open={open} maxWidth="sm" onClose={onClose}>
        <Form onClose={onClose} />
      </Dialog>
    </Tile>
  )
}

function Form({ onClose }) {
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const createAvailability = useAvailabilityCreate()

  const handleAdd = ([startTime, endTime]) => {
    const data = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      times: [{ start: startTime, end: endTime }],
    }
    return createAvailability(data).then(() => onClose())
  }

  return (
    <>
      <DialogTitle>Add availability to a range of dates</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={3} sx={{ alignItems: 'center' }}>
          <Alert severity="info" sx={{ width: '100%' }}>
            Adding a range will overwrite existing availability during those days
          </Alert>
          <StaticDateRangePicker
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            displayStaticWrapperAs="desktop"
            disablePast
          />
          {(startDate || endDate) && <AvailableItems dateRange={dateRange} onAdd={handleAdd} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button onClick={onClose} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </>
  )
}
