import { useMemo, useRef, useState } from 'react'
import sortBy from 'lodash/sortBy'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@components/_mui/Typography'

import { useMe } from '@shared/providers/src/MeProvider'

import { MedicalServiceIcon } from '@icons'

import { usePharmacies, usePharmacyUpdate, useSelectorState } from './PharmacySelector.hooks'

export default function PharmacySelector({ minified }) {
  const me = useMe()
  const theme = useTheme()
  const anchorRef = useRef(null)
  const [open, setOpen] = useSelectorState()
  const [search, setSearch] = useState('')

  const { data, isPending } = usePharmacies(me.id, {
    select: (pharmacies) => pharmacies.filter((pharmacy) => pharmacy.id !== me.pharmacy.id),
  })

  const filteredData = useMemo(() => {
    const filtered = data?.filter((pharmacy) => pharmacy.name.toLowerCase().includes(search?.toLowerCase())) ?? []
    return sortBy(filtered, 'name')
  }, [data, search])

  const updatePharmacy = usePharmacyUpdate()

  const handleChange = (pharmacy) => {
    if (me.pharmacy.id === pharmacy.id) return
    setOpen(false)
    updatePharmacy.mutate(pharmacy.id)
  }

  const hasData = data && data.length >= 1
  const isEmptySearch = search && !isPending && filteredData.length === 0

  return (
    <Collapse in={hasData} unmountOnExit>
      <Paper variant="outlined" ref={anchorRef} onClick={() => setOpen(true)} sx={{ m: 1, p: 1, cursor: 'pointer' }}>
        {minified ? (
          <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <MedicalServiceIcon style={{ fontSize: 20, color: theme.palette.text.secondary }} />
          </Stack>
        ) : (
          <Stack>
            <Typography sx={{ color: 'text.secondary' }}>Pharmacy</Typography>
            <Typography noWrap>{me.pharmacy?.name}</Typography>
          </Stack>
        )}
      </Paper>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{ paper: { sx: { width: 300, overflow: 'hidden' } } }}
      >
        <Box sx={{ m: 1 }}>
          <TextField
            autoFocus
            fullWidth
            size="large"
            variant="outlined"
            placeholder="Search..."
            autoComplete="off"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Divider />
        <List disablePadding sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
          {isEmptySearch && (
            <ListItemButton disabled>
              <ListItemText primary="No results found" />
            </ListItemButton>
          )}
          {filteredData?.map((pharmacy) => (
            <ListItemButton key={pharmacy.id} divider onClick={() => handleChange(pharmacy)}>
              <ListItemText primary={pharmacy.name} secondary={[pharmacy.city, pharmacy.state].filter(Boolean).join(', ')} />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Collapse>
  )
}
