import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { BulbOutlinedIcon } from '@icons'

const styles = {
  tip: {
    p: 1,
    gap: 1,
    alignItems: 'center',
    backgroundColor: 'grey.200',
    borderRadius: 2,
  },
}

export default function Tip({ children }) {
  return (
    <Stack direction="row" sx={styles.tip}>
      <BulbOutlinedIcon />
      <Typography>
        <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
          Tip.
        </Typography>
        {children}
      </Typography>
    </Stack>
  )
}
