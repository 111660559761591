import Chip from '@mui/material/Chip'

import { OnDemandStatus } from '@shared/utils'

import { CheckOutlinedIcon, ClockCircleOutlinedIcon, CloseCircleOutlinedIcon } from '@icons'

export default function OnDemandStatusChip({ value }) {
  if (value === OnDemandStatus.Pending) {
    return <Chip icon={<ClockCircleOutlinedIcon />} size="small" label="Pending" color="secondary" variant="combined" />
  }
  if (value === OnDemandStatus.Accepted) {
    return <Chip icon={<CheckOutlinedIcon />} size="small" label="Accepted" color="primary" />
  }
  if (value === OnDemandStatus.Rejected) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Rejected" color="error" variant="combined" />
  }
  if (value === OnDemandStatus.Abandoned) {
    return <Chip icon={<CloseCircleOutlinedIcon />} size="small" label="Abandoned" color="error" variant="combined" />
  }
  // Handle cases where a status is not known. This should never happen!!!
  return <Chip size="small" label="Unknown" color="error" />
}
