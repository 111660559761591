import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/styles/useTheme'
import Typography from '@components/_mui/Typography'

import { Content, Description, Page } from '@components/ErrorBase/ErrorBase.components'

export default function AppUpdated() {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fade in>
      <Page>
        <Content>
          <Description>
            <Typography variant={matchDownSM ? 'h2' : 'h1'} align="center">
              New Updates Deployed!
            </Typography>
            <Typography color="textSecondary" align="center">
              We've made some improvements.
              <br />
              Reload the page to experience the latest features.
            </Typography>
          </Description>
          <Button variant="contained" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </Content>
      </Page>
    </Fade>
  )
}
