import { useInfiniteQuery } from '@tanstack/react-query'

import API from '@shared/services/src/API'
import { flatten, pageParam, QK } from '@shared/utils'

const LIMIT = 50

export function useOnDemandAppointments(options = {}) {
  const query = { limit: LIMIT }

  return useInfiniteQuery({
    queryKey: QK.appointments.onDemand.list(query),
    queryFn: ({ pageParam }) => API.appointments.onDemand.list({ ...query, offset: pageParam * LIMIT }),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
    ...options,
  })
}
