import SvgIcon from '@mui/material/SvgIcon'

export default function PolicyOutlineIcon(props) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10 .833 2.5 4.167v5c0 4.625 3.2 8.95 7.5 10 4.3-1.05 7.5-5.375 7.5-10v-5L10 .833zm5.833 8.334a8.959 8.959 0 0 1-1.15 4.341L13.475 12.3a4.161 4.161 0 0 0-.533-5.242 4.17 4.17 0 0 0-5.892 0 4.17 4.17 0 0 0 0 5.892 4.172 4.172 0 0 0 5.242.533l1.433 1.434C12.733 16.1 11.45 17.008 10 17.45c-3.35-1.042-5.833-4.517-5.833-8.283V5.25L10 2.658l5.833 2.592v3.917zM10 12.5A2.497 2.497 0 0 1 7.5 10c0-1.383 1.117-2.5 2.5-2.5s2.5 1.117 2.5 2.5-1.117 2.5-2.5 2.5z"
        fill="#282B2A"
      />
    </SvgIcon>
  )
}
