import { forwardRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useUpdateEffect } from 'usehooks-ts'

import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import IconButton from '@components/_mui/IconButton'

import { getTestId } from '@shared/utils'

import { CloseOutlinedIcon, SearchOutlinedIcon } from '@icons'

/**
 * Stateless SearchInput Component which provides 'clear' input possibility
 *
 * @example
 * <SearchInput searchOnEnter value={value} onChange={handleChange} />
 */
const SearchInput = forwardRef((props, ref) => {
  const { value = '', label = '', onChange, searchOnEnter = false, disabled = false, size, ...rest } = props

  const testId = getTestId(rest, 'search-input')

  const [savedValue, setSavedValue] = useState(value)

  // Reset savedValue when searchOnEnter is enabled and value is empty
  useUpdateEffect(() => {
    if (searchOnEnter && !value) {
      setSavedValue('')
    }
  }, [value])

  const handleChange = useCallback(
    (value) => {
      if (searchOnEnter) {
        setSavedValue(value)
        return
      }

      onChange(value)
    },
    [onChange, searchOnEnter]
  )

  const handleKeyUp = (event) => {
    if (searchOnEnter && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
      onChange(savedValue)
    }
  }

  const handleClear = () => {
    onChange('')
    setSavedValue('')
  }

  return (
    <TextField
      ref={ref}
      fullWidth
      autoComplete="off"
      variant="outlined"
      value={searchOnEnter ? savedValue : value}
      label={label}
      placeholder="Search"
      onChange={(event) => handleChange(event.target.value)}
      onKeyUp={handleKeyUp}
      disabled={disabled}
      size={size}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              {value || savedValue ? (
                <IconButton
                  size={size}
                  color="inherit"
                  disabled={disabled}
                  sx={{ p: 0 }}
                  onClick={handleClear}
                  data-testid={`${testId}-clear`}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton size={size} color="inherit" sx={{ p: 0 }}>
                  <SearchOutlinedIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        },
        htmlInput: { 'data-testid': testId },
      }}
      {...rest}
    />
  )
})

SearchInput.propTypes = {
  /** The value for the input */
  value: PropTypes.string,

  /** Can display an optional label */
  label: PropTypes.string,

  /** Issue onChange after enter key is pressed */
  searchOnEnter: PropTypes.bool,

  /** Called after the search action */
  onChange: PropTypes.func.isRequired,
}

export default SearchInput
