import Badge from '@mui/material/Badge'

export default function Offline({ in: value, children, ...rest }) {
  return (
    <Badge
      variant="dot"
      color="secondary"
      overlap="circular"
      invisible={!value}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      {...rest}
      sx={{
        '& .MuiBadge-badge': {
          outline: '1px solid',
          outlineColor: 'background.paper',
          height: 10,
          minWidth: 10,
          borderRadius: '50%',
        },
      }}
    >
      {children}
    </Badge>
  )
}
