import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid2'

import Edit from './Edit'
import Preview from './Preview'
import Range from './Range'

export default function Availability() {
  return (
    <Fade in>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Grid container spacing={2}>
            <Grid size={12}>
              <Range />
            </Grid>
            <Grid size={12}>
              <Edit />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Preview />
        </Grid>
      </Grid>
    </Fade>
  )
}
