import CardActionArea from '@mui/material/CardActionArea'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

export default function SOP({ data, onSelect }) {
  const testId = `sop-${data.title?.substring(0, 10)}`

  return (
    <CardActionArea onClick={onSelect} data-testid={testId}>
      <Stack sx={{ p: 1, pb: 2 }}>
        <Typography variant="h5" data-testid={`${testId}-title`} sx={{ fontWeight: 'normal' }}>
          {data.title}
        </Typography>
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
          <Chip size="small" variant="outlined" color="primary" label={data.policy} sx={{ mr: 1 }} data-testid={`${testId}-policy`} />
          {data.categories?.map((category) => (
            <Chip key={category} size="small" variant="outlined" color="default" label={category} data-testid={`${testId}-category`} />
          ))}
        </Stack>
      </Stack>
      <Divider />
    </CardActionArea>
  )
}

SOP.Loading = function Loading() {
  return (
    <>
      <Stack sx={{ p: 1, pb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'normal' }}>
          <Skeleton width={300} />
        </Typography>
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
          <Skeleton width={70} sx={{ mr: 1 }} />
          <Skeleton width={50} />
          <Skeleton width={50} />
        </Stack>
      </Stack>
      <Divider />
    </>
  )
}
