import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router'

import ButtonMui from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@components/_mui/Typography'

import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import useSound from '@shared/hooks/src/useSound'
import { useSendMessage } from '@shared/messaging/src/hooks'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { handleErrorSilently, QK } from '@shared/utils'

import { CaretDownOutlinedIcon } from '@icons'
import Avatar from '@components/Avatar'

import { styles, Title } from '../Notifications.utils'
import { useGetPatient } from './Appointments.hooks'
import { getMessages } from './Appointments.utils'

/**
 * Handle the display of an Appointment notification.
 */
export default function Appointments() {
  const [appointment, setAppointment] = useState(undefined)
  const handleClose = () => setAppointment(undefined)

  const me = useMe()
  const getPatient = useGetPatient()
  const sendMessage = useSendMessage({ userId: appointment?.patient.id })
  const playSound = useSound({ howlProps: { src: '/sounds/notification1.mp3' } })

  const handleSendMessage = (message) => {
    return sendMessage.mutateAsync({ message }).then(() => {
      toast.success('Message sent')
      handleClose()
    })
  }

  usePubNub(`appointment_queue_${me.provider.id}`, async ({ action, attributes }) => {
    if (action !== Channels.PatientJoined) return

    try {
      const appointment = mapAppointment(attributes)
      const patient = await getPatient.mutateAsync(appointment.patientId)
      setAppointment({ ...appointment, patient })
      playSound()
      queryClient.invalidateQueries({ queryKey: QK.appointments.id(appointment.id).details })
      queryClient.invalidateQueries({ queryKey: QK.providers.id(me.provider.id).dashboard.appointments.lists })
      queryClient.invalidateQueries({ queryKey: QK.providers.id(me.provider.id).appointments.lists })
    } catch (e) {
      handleErrorSilently(e)
    }
  })

  return (
    <Snackbar
      open={Boolean(appointment)}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <div>
        <Stack spacing={1} sx={styles.container}>
          <Title label="Appointment" onClose={handleClose} />
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Avatar user={appointment?.patient} size="xs" />
            <Typography>{appointment?.patient.fullName}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
            <MessageButton appointment={appointment} onSend={handleSendMessage} />
            <Button component={Link} to={`/app/visits/${appointment?.id}`} onClick={handleClose}>
              Open Appt
            </Button>
          </Stack>
        </Stack>
      </div>
    </Snackbar>
  )
}

function MessageButton({ appointment, onSend }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => setAnchorEl(event?.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const messages = getMessages({
    patientId: appointment?.patient.id,
    appointmentId: appointment?.id,
  })

  return (
    <>
      <Button
        endIcon={<CaretDownOutlinedIcon />}
        id="message-menu-button"
        aria-controls={open ? 'message-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Message
      </Button>
      <Menu
        id="message-menu"
        disablePortal
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'message-menu-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {Object.entries(messages).map(([key, message]) => (
          <MenuItem
            key={key}
            onClick={() => {
              onSend(JSON.stringify(message))
              handleClose()
            }}
          >
            {key}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const Button = styled(ButtonMui)(({ theme }) => {
  const style = { color: theme.palette.text.contrast, backgroundColor: 'transparent' }
  return {
    ...style,
    '&:hover': style,
    '&:active': style,
    '&:focus': style,
  }
})

const mapAppointment = (attributes) => ({
  id: attributes.appointment_id,
  patientId: attributes.patient.id,
  patientJoinedAt: attributes.patient_joined_at,
})
