import { useEffect, useRef, useState } from 'react'
import copyToClipboard from 'clipboard-copy'

import Tooltip from '@mui/material/Tooltip'

export default function CopyToClipboard({ children }) {
  const timeoutRef = useRef(null)
  const [copied, setCopied] = useState(false)

  const handleCopy = (content) => {
    copyToClipboard(content).then(() => {
      setCopied(true)

      // Clear existing timeout if user clicks again within one second
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      // Set a new timeout to reset the 'copied' state after one second
      timeoutRef.current = setTimeout(() => {
        setCopied(false)
        timeoutRef.current = null
      }, 2000)
    })
  }

  // Cleanup timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const tooltipTitle = copied ? 'Copied' : 'Copy'

  return (
    <Tooltip title={tooltipTitle}>
      <span>{children({ copy: handleCopy, copied })}</span>
    </Tooltip>
  )
}
