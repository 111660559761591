import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@components/_mui/IconButton'

import { SOPsDialog, SOPsDrawer, useSOPsFilters } from '@pages/StandardOperatingProcess'
import { PolicyOutlineIcon } from '@icons'

export default function SOPs() {
  const [{ sops }, updateFilters] = useSOPsFilters()
  const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.5 }}>
        <IconButton
          id="sop-button"
          color="secondary"
          onClick={() => updateFilters({ sops: 'list' })}
          aria-label="SOPs toggler"
          data-testid="header-sops"
          size={matchDownSm ? 'small' : 'medium'}
          sx={{ color: 'text.primary' }}
        >
          <PolicyOutlineIcon />
        </IconButton>
      </Box>
      <SOPsDrawer open={Boolean(sops)} onClose={() => updateFilters({ sops: undefined })} />
      <SOPsDialog open={['view', 'edit', 'create'].includes(sops)} onClose={() => updateFilters({ sops: 'list', sopId: undefined })} />
    </>
  )
}
