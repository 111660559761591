import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import { useDebounce } from 'usehooks-ts'

import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import { getTestId } from '@shared/utils'

import usePharmacies from '@hooks/usePharmacies'
import { MedicalServiceIcon } from '@icons'
import Avatar from '@components/Avatar'

/** Wrapper around MUI Autocomplete to find and select pharmacy */
export default function SelectPharmacy({
  value: pharmacy,
  onChange,
  label,
  error,
  helperText,
  placeholder = 'Search',
  loading: outsideLoading = false,
  inputProps = {},
  ...rest
}) {
  const testId = getTestId(rest, 'pharmacy-selector')

  const [interacted, setInteracted] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const search = useDebounce(inputValue.trim(), 300)

  const { data, isPending, isRefreshing } = usePharmacies(
    { name: search, limit: 50, active: true },
    {
      // Increase stale time to avoid unnecessary requests
      staleTime: 60 * 1000,
      placeholderData: keepPreviousData,
      enabled: interacted,
    }
  )

  const loading = useLoadingState((interacted && (isPending || isRefreshing)) || outsideLoading)

  return (
    <Autocomplete
      autoComplete
      selectOnFocus
      disableCloseOnSelect={rest.multiple}
      onFocus={() => {
        if (!interacted) setInteracted(true)
      }}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      value={pharmacy}
      options={data || []}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onChange={(event, pharmacy) => onChange(pharmacy)}
      onInputChange={(event, search) => {
        if (!event || event.type === 'click') return setInputValue('')
        setInputValue(search)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          error={error}
          placeholder={placeholder}
          slotProps={{
            input: {
              ...params.InputProps,
              ...(!rest.multiple && {
                startAdornment: (
                  <Avatar.Icon size="xs" variant="rounded">
                    <MedicalServiceIcon style={{ fontSize: 16 }} />
                  </Avatar.Icon>
                ),
              }),
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
            htmlInput: { ...params.inputProps, 'data-testid': `${testId}-input` },
          }}
          {...inputProps}
        />
      )}
      renderOption={({ key, ...props }, pharmacy, { selected }) => (
        <li key={pharmacy.id} data-testid={`${testId}-${pharmacy.name}`} {...props}>
          {rest.multiple && <Checkbox sx={{ mr: 1 }} checked={selected} />}
          {pharmacy.name}
        </li>
      )}
      {...rest}
    />
  )
}
