import { isMobile } from 'react-device-detect'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { useMe } from '@shared/providers/src/MeProvider'

import CommunicationActions from './components/CommunicationActions'
import Content from './components/Content'
import Header from './components/Header'
import { useUserCard } from './UserCard.hooks'
import { bindDelayedHover } from './UserCard.utils'

const propTypes = {
  /** User id to fetch the user data */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

UserCard.propTypes = propTypes
UserCardPopper.propTypes = propTypes

export default function UserCard({ userId }) {
  const { data, isError, isPending } = useUserCard(userId)

  if (isPending) return <Loading />
  if (isError) return <Error />

  return (
    <Container>
      <Header user={data} />
      <Content user={data} />
      {data.accessible && <CommunicationActions user={data} />}
    </Container>
  )
}

export function UserCardPopper({ children, userId }) {
  const me = useMe()

  // Don't show popper if userId is not provided
  if (!userId) return children
  // Don't show popper for current user
  if (me.id === userId) return children

  return (
    <PopupState variant="popover" popupId="user-card-popover">
      {(popupState) => {
        const hoverHandler = isMobile ? bindHover(popupState) : bindDelayedHover(popupState, 750)

        return (
          <Box
            component="span"
            style={{ cursor: 'progress' }}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            onPointerDown={(e) => e.stopPropagation()}
          >
            <span {...hoverHandler}>{children}</span>
            <HoverPopover {...bindPopover(popupState)}>
              <UserCard userId={userId} />
            </HoverPopover>
          </Box>
        )
      }}
    </PopupState>
  )
}

function Loading() {
  return (
    <Container>
      <Header.Loading />
      <Content.Loading />
      <CommunicationActions />
    </Container>
  )
}

function Error() {
  return (
    <Container>
      <Stack sx={{ height: 200, justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h4" align="center" sx={{ width: 260 }}>
          Unexpected error occurred, please try again later.
        </Typography>
      </Stack>
    </Container>
  )
}

function Container({ children }) {
  return (
    <Paper sx={{ width: 430, height: '100%' }}>
      <Stack spacing={1} sx={{ p: 2 }}>
        {children}
      </Stack>
    </Paper>
  )
}
