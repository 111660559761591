import range from 'lodash/range'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import useOnDemandElapsedTime from '@shared/hooks/src/useOnDemandElapsedTime'
import { RoleGuard } from '@shared/providers/src/MeProvider'
import { OnDemandStatus, UserRole } from '@shared/utils'

import Property, { CBOProperty, ProviderPropertyContent } from '@components/Details/Property'
import Section from '@components/Details/Section'
import OnDemandStatusChip from '@components/OnDemandStatusChip'

import { useOnDemandAppointmentAccept } from '../OnDemandDetailsModal.hooks'

export default function DetailsSection({ data }) {
  const { color, formattedTime } = useOnDemandElapsedTime(data.createdAt)

  return (
    <Section paper mini title="Details" action={<Actions data={data} />}>
      <Property label="Treatment">{data.providerType.name}</Property>
      <Property label="Status">
        <OnDemandStatusChip value={data.status} />
      </Property>
      {data.status === OnDemandStatus.Pending && (
        <Property label="Elapsed Time" sx={{ value: { color } }}>
          {formattedTime}
        </Property>
      )}
      <CBOProperty label="CBO" data={data.cbo} />
      <Property label="Providers">
        <Stack spacing={1}>
          {data.providers.map((provider) => (
            <ProviderPropertyContent key={provider.id} user={provider} />
          ))}
        </Stack>
      </Property>
    </Section>
  )
}

DetailsSection.Loading = () => (
  <Section paper mini title="Details">
    <Property.Loading label="Treatment" />
    <Property.Loading label="Status" />
    <CBOProperty.Loading label="CBO" />
    <Property label="Providers">
      <Stack spacing={1}>
        {range(2).map((i) => (
          <ProviderPropertyContent.Loading key={i} />
        ))}
      </Stack>
    </Property>
  </Section>
)

function Actions({ data }) {
  const canBeAccepted = data.status === OnDemandStatus.Pending

  if (canBeAccepted) {
    return (
      <RoleGuard allowed={[UserRole.Provider]}>
        <AcceptButton data={data} />
      </RoleGuard>
    )
  }

  return null
}

function AcceptButton({ data }) {
  const accept = useOnDemandAppointmentAccept()

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        accept(data.id)
      }}
    >
      Accept
    </Button>
  )
}
