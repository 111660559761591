import React, { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateJSON } from '@tiptap/core'
import omitBy from 'lodash/omitBy'
import without from 'lodash/without'
import Papa from 'papaparse'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import MobileStepper from '@mui/material/MobileStepper'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DialogTitle from '@components/_mui/DialogTitle'

import useDialog from '@shared/hooks/src/useDialog'
import usePrompt from '@shared/hooks/src/usePrompt'
import { baseExtensions, containsHtml, richTextStyles } from '@shared/messaging/src/RichTextHelper'
import { convertPlainTextToJson, handleError, UserRole, userRoleToLabel } from '@shared/utils'

import { outlineRichTextEditorStyling } from '@utils/StylesHelper'
import { LeftOutlinedIcon, RightOutlinedIcon } from '@icons'
import Confirmation from '@components/Dialog/Confirmation'
import { getExtensions, RichTextEditor } from '@components/RichText'

import { useQPhraseCreation, useQPhraseUpdate } from './QPhrases.hooks'
import { AbbreviationMask, validationSchema } from './QPhrases.utils'

export default function QPhrasesImport() {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (!file) return

    Papa.parse(file, {
      complete: (results) => {
        const data = results.data.map((row) => ({
          abbreviation: row[0],
          value: row[1],
          title: row[2],
        }))
        setData(data)
        setOpen(true)
      },
      error: (error) => toast.error(`Error parsing CSV: ${error}`),
    })
  }

  const handleClose = () => {
    setOpen(false)
    setData([])
  }

  const confirmComplete = useDialog({
    component: Confirmation,
    props: ({ close }) => ({
      title: 'Are you sure you want to leave before completing the import?',
      description:
        'Closing this dialog will reset the process. If you leave now, you will need to go through all the Q Phrases  again, including those already saved, to complete the process.',
      rejectLabel: 'Cancel',
      confirmLabel: 'Confirm',
      onReject: () => close(),
      onConfirm: () => {
        close()
        handleClose()
      },
    }),
  })

  return (
    <>
      <input type="file" accept=".csv" value={''} onChange={handleFileChange} style={{ display: 'none' }} id="csv-file-input" />
      <label htmlFor="csv-file-input">
        <Button variant="contained" size="small" component="span">
          Import
        </Button>
      </label>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        open={open}
        onClose={(e, reason) => {
          if (['escapeKeyDown', 'backdropClick'].includes(reason)) return
          handleClose()
        }}
      >
        <DialogTitle onClose={() => confirmComplete()}>Import Q Phrases</DialogTitle>
        <DialogContent dividers>
          <Processor data={data} setData={setData} onDone={confirmComplete} />
        </DialogContent>
      </Dialog>
    </>
  )
}

const getInitialContent = (content) => {
  try {
    // Check if the content is already a tiptap JSON
    const result = JSON.parse(content)
    if (result?.type === 'doc') return result
  } catch (e) {
    // Check if the content is HTML to parse it
    if (containsHtml(content)) {
      return generateJSON(content, baseExtensions)
    }
    // Treat everything else as a plain text
    return convertPlainTextToJson(content)
  }

  return content || ''
}

const getInitialValues = (data) => {
  return {
    id: data?.id || '',
    category: data?.category || 'team',
    title: data?.title || '',
    abbreviation: data?.abbreviation ? data?.abbreviation.toLowerCase() : '',
    roles: data?.roles || without(Object.values(UserRole), UserRole.Patient),
    value: getInitialContent(data?.value),
  }
}

function Processor({ data, setData, onDone }) {
  const editorRef = useRef()

  usePrompt('Warning: Leaving this page will cancel the current import process. Are you sure you want to proceed?')

  const [activeStep, setActiveStep] = useState(0)

  const create = useQPhraseCreation({ skipHandling: true })
  const update = useQPhraseUpdate({ skipHandling: true })

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getInitialValues(data[activeStep]),
  })

  const changeStep = (step) => {
    const initialValues = getInitialValues(data[step])
    // Preserve roles from previous step to a new one
    if (!initialValues.id) initialValues.roles = form.watch('roles')

    form.reset(initialValues)
    editorRef.current?.commands.clearContent()
    editorRef.current?.commands.setContent(initialValues.value)
    setActiveStep(step)
  }

  const updateCurrentItem = (values) => setData((prev) => prev.map((item, index) => (index === activeStep ? { ...item, ...values } : item)))

  const handleBack = () => changeStep(activeStep - 1)

  const handleSkip = () => {
    updateCurrentItem({ skipped: true })
    const isLastStep = activeStep === data.length - 1
    if (isLastStep) return onDone()
    changeStep(activeStep + 1)
  }

  const handleNext = async (values) => {
    const message = editorRef.current?.getJSON()
    values.value = JSON.stringify(message)

    try {
      let item
      if (values.id) {
        item = await update.mutateAsync({ id: values.id, data: values })
      } else {
        item = await create.mutateAsync(values)
      }
      updateCurrentItem({ ...item, skipped: false })
      const isLastStep = activeStep === data.length - 1
      if (isLastStep) return onDone()
      changeStep(activeStep + 1)
    } catch (error) {
      updateCurrentItem({ error: error.message })
      handleError(error, { showResponse: true })
    }
  }

  const isSubmitting = form.formState.isSubmitting
  const isLastStep = activeStep === data.length - 1

  return (
    <form onSubmit={form.handleSubmit(handleNext)}>
      <Box sx={{ flexGrow: 1 }}>
        <MobileStepper
          variant="text"
          steps={data.length}
          position="static"
          activeStep={activeStep}
          backButton={
            <Button size="small" onClick={() => handleBack()} disabled={activeStep === 0 || isSubmitting} startIcon={<LeftOutlinedIcon />}>
              Back
            </Button>
          }
          nextButton={
            <Stack direction="row" spacing={1}>
              <Button size="small" onClick={handleSkip}>
                Skip
              </Button>
              <Button type="submit" size="small" disabled={isSubmitting} endIcon={isLastStep ? undefined : <RightOutlinedIcon />}>
                {isLastStep ? 'Complete' : 'Next'}
              </Button>
            </Stack>
          }
        />
        <Stack key={activeStep} spacing={2} sx={{ py: 2 }}>
          {data[activeStep]?.skipped && (
            <Alert variant="border" severity="info">
              <AlertTitle>This Q Phrase was skipped before</AlertTitle>
            </Alert>
          )}
          {data[activeStep]?.id && (
            <Alert variant="border" severity="info">
              <AlertTitle>This Q Phrase was already imported and any changes will update existing entity</AlertTitle>
            </Alert>
          )}
          <Controller
            name="title"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Title"
                variant="outlined"
                fullWidth
                disabled={isSubmitting}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="abbreviation"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Abbreviation"
                variant="outlined"
                fullWidth
                disabled={isSubmitting}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                slotProps={{
                  input: {
                    inputComponent: AbbreviationMask,
                    endAdornment: <InputAdornment position="end">Originally: {data?.[activeStep]?.abbreviation || 'Empty'}</InputAdornment>,
                  },
                }}
              />
            )}
          />
          <Controller
            name="roles"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Roles"
                variant="outlined"
                fullWidth
                disabled={isSubmitting}
                select
                slotProps={{ select: { multiple: true } }}
              >
                {Object.values(omitBy(UserRole, (role) => role === UserRole.Patient)).map((role) => (
                  <MenuItem key={role} value={role}>
                    {userRoleToLabel[role]}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Box sx={[outlineRichTextEditorStyling, richTextStyles, { '& .tiptap': { minHeight: 150 } }]}>
            <RichTextEditor
              ref={editorRef}
              selectorEnabled
              dateEnabled
              extensions={getExtensions({ selectorsEditable: true })}
              initialValue={getInitialContent(data?.[activeStep]?.value)}
            />
          </Box>
        </Stack>
      </Box>
    </form>
  )
}
