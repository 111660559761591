import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { QK, UserRole } from '@shared/utils'

export function useUsers({ role, search, limit = 15, status, dob }, options = {}) {
  const query = { role, search_term: search, status, limit, dob }

  const isPatient = role === UserRole.Patient
  const queryKey = isPatient ? QK.patients.list(query) : QK.systemUsers.list(query)
  const queryFn = isPatient ? () => API.patients.list(query) : () => API.systemUsers.list(query)

  return useQuery({ queryKey, queryFn, ...options })
}
