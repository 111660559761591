import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Extension } from '@tiptap/core'
import { ReactRenderer } from '@tiptap/react'
import { Suggestion } from '@tiptap/suggestion'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { useAllQPhrases } from '@providers/QPhrasesProvider'
import useQPhraseUsage from '@hooks/useQPhrasesUsage'

export const QPhrasesList = React.forwardRef(({ clientRect, command, query, editor }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const referenceEl = useMemo(() => (clientRect ? { nodeType: 1, getBoundingClientRect: clientRect } : null), [clientRect])

  const phrases = useAllQPhrases(query)
  const { increment } = useQPhraseUsage()

  useEffect(() => {
    if (phrases?.length - 1 >= selectedIndex) return
    setSelectedIndex(0)
  }, [phrases?.length, query, selectedIndex])

  const upHandler = () => setSelectedIndex((selectedIndex + phrases?.length - 1) % phrases?.length)
  const downHandler = () => setSelectedIndex((selectedIndex + 1) % phrases?.length)

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === ' ') {
        const phrase = phrases?.[selectedIndex]
        if (phrase) {
          command(phrase)
          increment(phrase.id)
        }
        return false
      }

      return false
    },
  }))

  return (
    <Popper open={Boolean(clientRect) && phrases?.length > 0} anchorEl={referenceEl} placement="top-start" sx={{ zIndex: 1301 }}>
      <Paper>
        <Stack sx={{ width: '100%', maxWidth: 400, alignItems: 'center' }}>
          <List dense disablePadding sx={{ width: '100%' }}>
            {phrases?.map((item, index) => (
              <ListItemButton key={index} divider selected={selectedIndex === index}>
                <ListItemText primary={item.title} secondary={item.abbreviation} />
              </ListItemButton>
            ))}
          </List>
          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', maxWidth: 240, p: 1 }}>
            Use the arrow keys to select a <b>Q Phrase</b>, then press <b>Space</b> to insert it or <b>Esc</b> to cancel.
          </Typography>
        </Stack>
      </Paper>
    </Popper>
  )
})

const QPhrasesExtension = Extension.create({
  name: 'q-phrases',
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        char: '/',
        command: ({ editor, range, props: item }) => {
          editor.chain().focus().deleteRange(range).insertContentAt(range.from, JSON.parse(item.value)).run()
        },
        render: () => {
          let component

          return {
            onStart: (props) => {
              component = new ReactRenderer(QPhrasesList, {
                props,
                editor: props.editor,
              })
            },

            onUpdate: (props) => {
              component?.updateProps(props)
            },

            onKeyDown: (props) => {
              if (props.event.key === 'Escape') {
                component?.destroy()
                return true
              }

              return component.ref?.onKeyDown(props)
            },
            onExit: () => {
              component.destroy()
            },
          }
        },
      }),
    ]
  },
})

export default QPhrasesExtension
