import { forwardRef, useRef, useState } from 'react'
import { useHover } from 'usehooks-ts'

import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import TypographyMui from '@mui/material/Typography'

import { CopyOutlinedIcon } from '@icons'
import CopyToClipboard from '@components/CopyToClipboard'

/**
 * Extend MUI Typography with tooltip on overflow.
 */
const Typography = forwardRef(({ copy, ...props }, ref) => {
  // Optimization for when we know the text will overflow
  if (props.noWrap === true) {
    return <Ellipsis ref={ref} {...props} />
  }

  // When 'copy' is a boolean with true value, we copy the children
  const copyBool = copy === true && ['string', 'number'].includes(typeof props.children)
  // When 'copy' is a string or a number, we copy the copy value instead
  const copyValue = ['string', 'number'].includes(typeof copy)

  if (copyBool || copyValue) {
    const value = copyValue ? copy : props.children
    return <Copy ref={ref} value={value} {...props} />
  }

  return <TypographyMui ref={ref} {...props} />
})

const Ellipsis = forwardRef(({ children, ...props }, ref) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false)

  const handleShouldShow = ({ currentTarget }) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true)
    }
  }

  return (
    <Tooltip open={tooltipEnabled} title={children} onClose={() => setTooltipEnabled(false)}>
      <TypographyMui ref={ref} onMouseEnter={handleShouldShow} {...props}>
        {children}
      </TypographyMui>
    </Tooltip>
  )
})

const Copy = forwardRef(({ value, ...props }, ref) => {
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)

  return (
    <CopyToClipboard>
      {({ copy }) => (
        <Stack
          ref={hoverRef}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            copy(value)
          }}
          sx={{ flexDirection: 'row', alignItems: 'center', gap: 1, cursor: 'pointer' }}
        >
          <TypographyMui ref={ref} {...props} />
          <Fade in={isHover}>
            <CopyIcon />
          </Fade>
        </Stack>
      )}
    </CopyToClipboard>
  )
})

const CopyIcon = styled(CopyOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
}))

export default Typography
