export default function Container(theme) {
  return {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
      styleOverrides: {
        root: {
          flexGrow: 1,
          padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
          },
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(3),
          },
        },
      },
    },
  }
}
