import { useMemo } from 'react'
import dayjs from 'dayjs'

import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@components/_mui/Typography'

import useIntervalResult from '@shared/hooks/src/useIntervalResult'
import { getTimezoneShift, mapRailsTimezoneToJS, userRoleToLabel } from '@shared/utils'

import { ClockCircleOutlinedIcon } from '@icons'

import { Avatar } from '../UserCard.utils'

export default function Header({ user }) {
  return (
    <Stack direction="row" spacing={2}>
      <Avatar user={user} size="xxl" />
      <Stack>
        <Typography variant="h4">{user.fullName}</Typography>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-end' }}>
          <Typography>{userRoleToLabel[user.role]}</Typography>
          {user.pronouns && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user.pronouns}
            </Typography>
          )}
        </Stack>
        <Time user={user} />
      </Stack>
    </Stack>
  )
}

Header.Loading = function () {
  return (
    <Stack direction="row" spacing={2}>
      <Skeleton variant="rounded" width={84} height={84} />
      <Stack>
        <Typography variant="h4">
          <Skeleton width={150} />
        </Typography>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-end' }}>
          <Typography>
            <Skeleton width={50} />
          </Typography>
        </Stack>
        <Time.Loading />
      </Stack>
    </Stack>
  )
}

function Time({ user }) {
  const userTimezone = mapRailsTimezoneToJS(user.timezone)

  const timezoneDescription = useMemo(() => getTimezoneShift(userTimezone), [userTimezone])

  const time = useIntervalResult(() => {
    try {
      const myTime = dayjs().format('LT')

      if (!userTimezone) return `${myTime} Timezone not set`

      const userTime = dayjs().tz(userTimezone).format('LT')
      const isSame = myTime === userTime

      return `${userTime} ${isSame ? 'same as you' : 'local time'}`
    } catch (e) {
      return '-'
    }
  }, 5000)

  return (
    <Tooltip arrow title={timezoneDescription}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <ClockIcon />
        <Typography>{time}</Typography>
      </Stack>
    </Tooltip>
  )
}

Time.Loading = function () {
  return (
    <Typography>
      <Skeleton width={120} />
    </Typography>
  )
}

const ClockIcon = styled(ClockCircleOutlinedIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))
