import SvgIcon from '@mui/material/SvgIcon'

export default function Environment(props) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.118 4.02a6.474 6.474 0 0 0-1.427-2.065A6.697 6.697 0 0 0 8 .055a6.73 6.73 0 0 0-2.58.509c-.791.326-1.5.794-2.11 1.389a6.493 6.493 0 0 0-1.428 2.066 6.342 6.342 0 0 0-.525 2.536c0 1.26.302 2.516.895 3.726.477.974 1.143 1.922 1.982 2.824a15.948 15.948 0 0 0 3.364 2.732.784.784 0 0 0 .8 0 15.947 15.947 0 0 0 3.365-2.732c.84-.9 1.505-1.85 1.982-2.824.596-1.208.898-2.462.898-3.725a6.34 6.34 0 0 0-.525-2.535zM8 14.577c-1.177-.748-5.357-3.71-5.357-8.02 0-1.39.555-2.698 1.564-3.683A5.397 5.397 0 0 1 8 1.34c1.434 0 2.78.545 3.793 1.534a5.108 5.108 0 0 1 1.564 3.682c0 4.31-4.18 7.272-5.357 8.02zM8 3.555A3.143 3.143 0 1 0 8 9.84a3.143 3.143 0 0 0 0-6.285zm1.415 4.557A1.993 1.993 0 0 1 8 8.698a1.993 1.993 0 0 1-1.848-1.235 1.993 1.993 0 0 1 .434-2.18c.379-.378.88-.585 1.414-.585.534 0 1.036.207 1.415.585.378.379.585.88.585 1.415 0 .533-.207 1.035-.585 1.414z"
        fill="#949696"
      />
    </SvgIcon>
  )
}
