import { Fragment } from 'react'
import { useController, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'
import partition from 'lodash/partition'
import range from 'lodash/range'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Link from '@components/_mui/Link'
import Typography from '@components/_mui/Typography'

import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { useMe } from '@shared/providers/src/MeProvider'
import { mapRailsTimezoneToJS, UserRole } from '@shared/utils'

import Avatar from '@components/Avatar'

import { usePatientEncounterAvailabilities } from '../AppointmentScheduleModal.hooks'
import { appointmentToSlot, mapData, splitTimesIntoPeriods } from '../AppointmentScheduleModal.utils'

const styles = {
  slots: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
    gap: 1,
  },
}

export default function SlotsSection({ appointment, patient, encounter }) {
  const timeslotField = useController({ name: 'timeslot' })
  const timezoneField = useController({ name: 'timezone' })
  const date = useWatch({ name: 'date' })
  const provider = useWatch({ name: 'provider' })
  const timezone = timezoneField.field.value

  const query = usePatientEncounterAvailabilities(
    patient?.id,
    encounter?.id,
    {
      provider_id: provider?.id,
      view_as: timezone,
      start_date: date.format('YYYY-MM-DD'),
      end_date: date.format('YYYY-MM-DD'),
    },
    {
      enabled: Boolean(patient?.id) && Boolean(encounter?.id),
      select: (data) => {
        const slots = Array.from(data)
        const currentTimeslot = appointmentToSlot(appointment)
        if (currentTimeslot && dayjs(currentTimeslot.date).isSame(date, 'day')) {
          slots.push(currentTimeslot)
        }
        return mapData(slots)
      },
    }
  )

  useQueryEvents(query, {
    onSuccess: (data) => {
      const selectedSlot = timeslotField.field.value
      const slot = data.find((provider) => provider.id === selectedSlot?.providerId)?.slots.find((slot) => slot.id === selectedSlot.id)
      if (!slot) timeslotField.field.onChange(null)
    },
  })

  const { data, isPending } = query
  const showLoading = isPending
  const showEmpty = !showLoading && data?.length === 0
  const showData = !showEmpty && data?.length > 0

  const isProviderTimezone = timezoneField.field.value === 'provider'
  const assignedProvider = patient?.provider?.id
  const [assigned, available] = partition(data, (provider) => provider.id === assignedProvider)

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{ pb: 1 }}>
            {date.format('dddd, MMMM D')}
          </Typography>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Typography>Timezone</Typography>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={timezoneField.field.value}
              exclusive
              onChange={(e, v) => {
                if (v) timezoneField.field.onChange(v)
              }}
            >
              <ToggleButton value="provider">Provider</ToggleButton>
              <ToggleButton value="patient">
                Patient {patient ? `(${dayjs().tz(mapRailsTimezoneToJS(patient.timezone)).format('z')})` : ''}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          {showLoading &&
            range(3).map((i) => (
              <Fragment key={i}>
                <Divider />
                <Provider.Loading />
              </Fragment>
            ))}
          {showEmpty && <Empty />}

          {showData && (
            <>
              {assigned.map((provider) => (
                <Provider
                  key={provider.id}
                  isAssigned
                  data={provider}
                  timezone={mapRailsTimezoneToJS(isProviderTimezone ? provider.timezone : patient.timezone)}
                  selectedSlot={timeslotField.field.value}
                  onSelect={(slot) => timeslotField.field.onChange(slot)}
                />
              ))}
              {available.map((provider) => (
                <Provider
                  key={provider.id}
                  data={provider}
                  timezone={mapRailsTimezoneToJS(isProviderTimezone ? provider.timezone : patient.timezone)}
                  selectedSlot={timeslotField.field.value}
                  onSelect={(slot) => timeslotField.field.onChange(slot)}
                />
              ))}
            </>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}

function Provider({ data, timezone, selectedSlot, onSelect, isAssigned }) {
  const { morning, afternoon, evening } = splitTimesIntoPeriods(data.slots, timezone)

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Avatar user={data} size="xl" hover="card" />
        <Stack spacing={-0.5}>
          <Typography>
            {data.fullName}
            <b>{isAssigned ? ' (Assigned)' : ''}</b>
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Timezone: <b>{dayjs().tz(mapRailsTimezoneToJS(data.timezone)).format('zzz')}</b>
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {data.servicingNewPatients ? 'Accepting New Patients' : 'Not Accepting New Patients'}
          </Typography>
        </Stack>
      </Stack>
      <Slots title="Morning" data={morning} timezone={timezone} selectedSlot={selectedSlot} onSelect={onSelect} />
      <Slots title="Afternoon" data={afternoon} timezone={timezone} selectedSlot={selectedSlot} onSelect={onSelect} />
      <Slots title="Evening" data={evening} timezone={timezone} selectedSlot={selectedSlot} onSelect={onSelect} />
    </Stack>
  )
}

Provider.Loading = function () {
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Skeleton variant="rounded" width={40} height={40} />
        <Stack spacing={-0.5}>
          <Typography>
            <Skeleton width={200} />
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            <Skeleton width={150} />
          </Typography>
        </Stack>
      </Stack>
      <Slots.Loading title="Morning" />
      <Slots.Loading title="Afternoon" />
      <Slots.Loading title="Evening" />
    </Stack>
  )
}

function Slots({ title, data = [], timezone, selectedSlot, onSelect }) {
  if (!data.length) return null

  return (
    <Stack>
      <Typography variant="h5" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>
      <Box sx={styles.slots}>
        {data.map((slot) => (
          <Chip
            key={slot.id}
            color={slot.current || selectedSlot?.id === slot.id ? 'primary' : 'default'}
            label={dayjs(slot.timeSlot).tz(timezone).format('LT')}
            onClick={() => onSelect(slot)}
            disabled={slot.current}
          />
        ))}
      </Box>
    </Stack>
  )
}

Slots.Loading = function ({ title }) {
  return (
    <Stack>
      <Typography variant="h5" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>
      <Box sx={styles.slots}>
        {range(6).map((i) => (
          <Chip key={i} label={<Skeleton width={50} />} />
        ))}
      </Box>
    </Stack>
  )
}

function Empty() {
  const me = useMe()
  const providerField = useController({ name: 'provider' })
  const isProviderSelected = Boolean(providerField.field.value) && me.role !== UserRole.Provider

  return (
    <Stack spacing={2} sx={{ height: 200, justifyContent: 'center' }}>
      <Typography variant="h4" align="center" sx={{ fontWeight: 'normal', color: 'text.secondary' }}>
        {`No slots for the selected day${isProviderSelected ? ' & provider' : ''}.`}
      </Typography>
      {isProviderSelected ? (
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
          <Typography variant="h4" align="center" sx={{ fontWeight: 'normal', color: 'text.secondary' }}>
            Pick another day or
          </Typography>
          <Link component="button" variant="h4" onClick={() => providerField.field.onChange(null)}>
            view all providers.
          </Link>
        </Stack>
      ) : (
        <Typography variant="h4" align="center" sx={{ fontWeight: 'normal', color: 'text.secondary' }}>
          Pick another day.
        </Typography>
      )}
    </Stack>
  )
}
