import { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Stack from '@mui/material/Stack'
import IconButton from '@components/_mui/IconButton'
import Typography from '@components/_mui/Typography'

import useEffectState from '@shared/hooks/src/useEffectState'

import { LeftOutlinedIcon, RightOutlinedIcon } from '@icons'

/**
 * Component to display the current date and allow changing it by day or week
 * Works only with dayjs objects
 *
 * @param date - dayjs object
 * @param onDateChange - function to handle date change
 * @param view - string, 'day' or 'week'
 * @param onViewChange - function to handle view change
 * @param action - action to display on the right
 */
export default function CalendarDate({ date = dayjs(), onDateChange, view, onViewChange, action }) {
  // Inner state for date
  const [value, setValue] = useEffectState(date)

  // Debounce date change
  useEffect(() => {
    const id = setTimeout(() => onDateChange(value), 1000)
    return () => clearTimeout(id)
  }, [onDateChange, value])

  const handlePrev = () => setValue((v) => v.subtract(1, view))
  const handleNext = () => setValue((v) => v.add(1, view))

  const label = useMemo(() => {
    if (view === 'day') {
      return value.format('MMMM D, YYYY')
    }

    if (view === 'week') {
      const start = value.startOf('week')
      const end = value.endOf('week')

      // December 31, 2024 - January 6, 2025
      if (start.year() !== end.year()) {
        return `${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`
      }
      // November 10 - December 12, 2024
      if (start.month() !== end.month()) {
        return `${start.format('MMMM D')} - ${end.format('MMMM D, YYYY')}`
      }
      // December 1 - 6, 2024
      return `${start.format('MMMM D')} - ${end.format('D, YYYY')}`
    }
  }, [value, view])

  return (
    <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
      <Button variant="outlined" onClick={() => setValue(dayjs())}>
        Today
      </Button>
      <Stack direction="row" id="calendar-date-selector" sx={{ alignItems: 'center', justifyContent: 'center', flex: '1 1 auto' }}>
        <IconButton onClick={handlePrev}>
          <LeftOutlinedIcon />
        </IconButton>
        <Typography variant="h5" align="center" sx={{ minWidth: 280 }} noWrap>
          {label}
        </Typography>
        <IconButton onClick={handleNext}>
          <RightOutlinedIcon />
        </IconButton>
      </Stack>
      {onViewChange ? (
        <ButtonGroup>
          <Button variant={view === 'day' ? 'contained' : 'outlined'} onClick={() => onViewChange('day')}>
            Daily
          </Button>
          <Button variant={view === 'week' ? 'contained' : 'outlined'} onClick={() => onViewChange('week')}>
            Weekly
          </Button>
        </ButtonGroup>
      ) : (
        <div />
      )}
      {action}
    </Stack>
  )
}
