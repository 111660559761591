import { useState } from 'react'
import { mergeAttributes, Node } from '@tiptap/core'
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'
import dayjs from 'dayjs'

import { DatePicker } from '@mui/x-date-pickers-pro'

const DateNodeView = ({ node, updateAttributes, editor }) => {
  const { date } = node.attrs
  const isEditable = editor.isEditable

  const [selectedDate, setSelectedDate] = useState(date ? dayjs(date) : null)

  return (
    <NodeViewWrapper contentEditable={false} style={{ display: 'inline-flex', alignItems: 'center' }}>
      <DatePicker
        value={selectedDate}
        onChange={(date) => {
          setSelectedDate(date)
          if (date?.isValid()) updateAttributes({ date })
        }}
        onAccept={(date) => updateAttributes({ date })}
        disabled={!isEditable || node.attrs.disabled || false}
        slotProps={{ textField: { variant: 'standard', inputProps: { sx: { p: 0, maxWidth: 100 } } } }}
      />
    </NodeViewWrapper>
  )
}

export const DateExtension = Node.create({
  name: 'date',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      date: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-date') || '',
        renderHTML: (attributes) => ({
          'data-date': attributes.date,
        }),
      },
      disabled: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-disabled') === 'true',
        renderHTML: (attributes) => ({
          'data-disabled': attributes.disabled,
        }),
      },
    }
  },

  parseHTML() {
    return [{ tag: 'span[data-date]' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, { 'data-date': '' }), `Date: ${HTMLAttributes.date || 'Select Date'}`]
  },

  addNodeView() {
    return ReactNodeViewRenderer(DateNodeView)
  },

  addCommands() {
    return {
      insertDate: (date) => {
        return ({ commands }) => {
          commands.insertContent({ type: this.name, attrs: { date } })
          return commands.insertContent(' ')
        }
      },
    }
  },
})

export default DateExtension
