import { Link as RouterLink } from 'react-router'

import Stack from '@mui/material/Stack'
import Link from '@components/_mui/Link'

import { AppointmentIcon } from '@icons'
import AppointmentStatusChip from '@components/AppointmentStatusChip'
import Property, { ProviderProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'

import { useAppointment } from '../../AppointmentDetailsModal/AppointmentDetailsModal.hooks'

export default function AppointmentSection({ appointmentId }) {
  const { data, isPending } = useAppointment(appointmentId)

  return (
    <Section paper mini title="Appointment">
      {isPending ? (
        <>
          <Property.Loading label="ID" />
          <Property.Loading label="Reason" />
          <Property.Loading label="Status" />
          <ProviderProperty.Loading label="Provider" />
        </>
      ) : (
        <>
          <Property label="ID">{data.id}</Property>
          <Property label="Reason">
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <AppointmentIcon type={data.type} />
              <Link
                component={RouterLink}
                to={`/app/patients/${data.userId}/encounters/${data.encounterId}`}
                fontWeight="bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.visitReason}
              </Link>
            </Stack>
          </Property>
          <Property label="Status">
            <AppointmentStatusChip value={data.status} />
          </Property>
          <ProviderProperty label="Provider" user={data.provider} />
        </>
      )}
    </Section>
  )
}
