import { useMemo } from 'react'
import dayjs from 'dayjs'
import { partition } from 'lodash'
import PropTypes from 'prop-types'

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import useIsDatePassed from '@shared/hooks/src/useIsDatePassed'

AvailabilityDay.propTypes = {
  // The date to display availability for. (dayjs)
  date: PropTypes.object.isRequired,

  // Whether the data is loading.
  isLoading: PropTypes.bool,

  // Can display custom label.
  label: PropTypes.node,

  // The availability data to display.
  availability: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    })
  ),
}

const styles = {
  date: (theme) => ({
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[600],
  }),
  blackout: {
    px: 1,
    textAlign: 'center',
    backgroundColor: 'primary.700',
    color: 'text.contrast',
    borderRadius: 1,
  },
}

export default function AvailabilityDay({ isLoading = false, date, availability = [], blackouts = [], label }) {
  const items = useMemo(() => {
    const [allDayBlackouts, periodBlackouts] = partition(blackouts, (blackout) => blackout.allDay)
    const sorted = [...availability, ...periodBlackouts].sort((a, b) => a.start.localeCompare(b.start))
    return [...allDayBlackouts, ...sorted]
  }, [availability, blackouts])

  const showLoading = isLoading
  const showEmpty = !showLoading && availability.length === 0 && blackouts.length === 0
  const showData = !showEmpty && (availability.length > 0 || blackouts.length > 0)

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Avatar variant="rounded" sx={styles.date} data-testid="date-today">
          {date.format('DD')}
        </Avatar>
        {label || (
          <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-end' }}>
            <Typography variant="h5">{date.format('MMMM')}</Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {date.format('dddd')}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack spacing={1} data-testid="availability-list">
        {showLoading && <Loading />}
        {showEmpty && <Empty />}
        {showData &&
          items.map((data) => {
            const Component = data.type === 'blackout_periods' ? Blackout : Availability
            return <Component key={data.id} data={data} />
          })}
      </Stack>
    </Stack>
  )
}

function Availability({ data }) {
  const { start, end } = data
  const isPassed = useIsDatePassed(end)

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center', pl: 7 }}>
      <Typography noWrap sx={{ color: isPassed ? 'text.secondary' : 'text.primary' }}>
        {dayjs(start).tz(window.timezone).format('LT')} - {dayjs(end).tz(window.timezone).format('LT')}
      </Typography>
    </Stack>
  )
}

function Blackout({ data }) {
  const title = useMemo(() => {
    if (data.allDay) return `All day - ${data.title}`
    return `${dayjs(data.start).tz(window.timezone).format('LT')} - ${dayjs(data.end).tz(window.timezone).format('LT')} - ${data.title}`
  }, [data])

  return (
    <Box sx={styles.blackout}>
      <Typography noWrap>{title}</Typography>
    </Box>
  )
}

const Loading = () => (
  <Typography sx={{ pl: 7 }}>
    <Skeleton width={120} />
  </Typography>
)

const Empty = () => <Typography sx={{ pl: 7, color: 'text.secondary' }}>unavailable</Typography>
