import { keepPreviousData, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import API from '@shared/services/src/API'
import { flatten, handleError, pageParam, QK } from '@shared/utils'

export function useAppointment(appointmentId, options = {}) {
  return useQuery({
    queryKey: QK.appointments.id(appointmentId).details,
    queryFn: () => API.appointments.id(appointmentId).details(),
    ...options,
  })
}

const NOTES_LIMIT = 5

export function useAppointmentNotes(appointmentId) {
  const query = { limit: NOTES_LIMIT }
  return useInfiniteQuery({
    queryKey: QK.appointments.id(appointmentId).notes.list(query),
    queryFn: ({ pageParam }) => API.appointments.id(appointmentId).notes.list({ ...query, offset: pageParam * NOTES_LIMIT }),
    placeholderData: keepPreviousData,
    enabled: Boolean(appointmentId),
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(NOTES_LIMIT),
  })
}

export function useAppointmentNoteCreation(appointmentId, onCreate) {
  return useMutation({
    mutationFn: (message) => API.appointments.id(appointmentId).notes.create({ message }),
    onSuccess: () => {
      onCreate?.()
      return queryClient.invalidateQueries({ queryKey: QK.appointments.id(appointmentId).notes.lists })
    },
    onError: handleError,
  })
}

export function useMarkAppointmentAsMissed() {
  const me = useMe()

  return useMutation({
    mutationFn: (id) => API.appointments.id(id).markAsMissed(),
    onSuccess: (appointment) => handleStatusChange(appointment, me.provider?.id),
    onError: (e) => handleError(e, { message: 'Failed to mark appointment as missed' }),
  })
}

export function useMarkAppointmentAsCanceled() {
  const me = useMe()

  return useMutation({
    mutationFn: (id) => API.appointments.id(id).markAsCanceled(),
    onSuccess: (appointment) => handleStatusChange(appointment, me.provider?.id),
    onError: (e) => handleError(e, { message: 'Failed to cancel appointment' }),
  })
}

const handleStatusChange = (appointment, providerId) => {
  queryClient.setQueryData(QK.appointments.id(appointment.id).details, (old) => ({ ...old, ...appointment }))
  queryClient.invalidateQueries({ queryKey: QK.appointments.lists })
  if (appointment.provider.id === providerId) {
    queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).dashboard.appointments.lists })
    queryClient.invalidateQueries({ queryKey: QK.providers.id(providerId).appointments.lists })
  }
}

export const invalidateEncounterNotes = (appointment) => {
  if (!appointment) return

  const patientId = appointment?.userId
  const encounterId = appointment?.encounterId

  if (!patientId || !encounterId) return

  queryClient.invalidateQueries({ queryKey: QK.patients.id(patientId).encounters.id(encounterId).notes.lists })
}
