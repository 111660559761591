import { del, get, set } from 'idb-keyval'

const idbValidKey = 'react-query'

export const createIDBPersister = () => ({
  persistClient: async (client) => {
    await set(idbValidKey, client)
  },
  restoreClient: async () => {
    return await get(idbValidKey)
  },
  removeClient: async () => {
    await del(idbValidKey)
  },
})

export const isIDBAvailable = () => typeof window !== 'undefined' && 'indexedDB' in window
