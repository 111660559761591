import Avatar from '@mui/material/Avatar'

import { UserOutlinedIcon } from '@icons'

import { getSizeStyle } from './Avatar.utils'

export function AvatarPlaceholder({ variant = 'rounded', size = 'md', sx, ...others }) {
  return (
    <Avatar
      alt="profile picture placeholder"
      variant={variant}
      sx={[getSizeStyle(size), { color: 'primary.contrastText', backgroundColor: 'grey.500' }, sx]}
      {...others}
    >
      <UserOutlinedIcon />
    </Avatar>
  )
}
