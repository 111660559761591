import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import { getTestId } from '@shared/utils'

import Avatar from '@components/Avatar'

export default function Provider({ small = false, user, children, ...rest }) {
  if (!user) return null

  const testId = getTestId(rest, `provider-${user.name}`)

  return (
    <Stack direction="row" spacing={1} data-testid={testId} sx={{ alignItems: 'center' }}>
      <Avatar user={user} size={small ? 'sm' : 'md'} hover="card" />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'} data-testid={`${testId}-name`}>
          {user.name}
        </Typography>
        {children || (
          <Typography variant={small ? 'body2' : 'body1'} data-testid={`${testId}-subtitle`} sx={{ color: 'text.secondary' }}>
            {[user.city, user.state].filter(Boolean).join(', ')}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

Provider.Loading = function ({ small }) {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Skeleton variant="rounded" width={small ? 32 : 40} height={small ? 32 : 40} />
      <Stack spacing={-0.5}>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={150} />
        </Typography>
        <Typography variant={small ? 'body2' : 'body1'}>
          <Skeleton width={120} />
        </Typography>
      </Stack>
    </Stack>
  )
}
