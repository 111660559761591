import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@components/_mui/DialogTitle'
import Typography from '@components/_mui/Typography'

import { getTestId } from '@shared/utils'

import LinearProgress from '@components/LinearProgress'

Confirmation.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** Title of the dialog */
  title: PropTypes.string.isRequired,

  /** Description of the dialog */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** Label of 'reject' button */
  rejectLabel: PropTypes.string,

  /** Color of 'reject' button */
  rejectColor: PropTypes.string,

  /** Label of 'confirm' button */
  confirmLabel: PropTypes.string,

  /** Color of 'confirm' button */
  confirmColor: PropTypes.string,

  /** Called after the reject button is clicked */
  onReject: PropTypes.func.isRequired,

  /** Called after the confirm button is clicked */
  onConfirm: PropTypes.func.isRequired,

  /* Can show linear progress on top */
  loading: PropTypes.bool,
}

export default function Confirmation({
  open,
  title,
  description,
  rejectLabel = 'Cancel',
  rejectColor = 'error',
  confirmLabel = 'Confirm',
  confirmColor = 'primary',
  onReject,
  onConfirm,
  loading = false,
  maxWidth = 'sm',
  ...rest
}) {
  const testId = getTestId(rest, 'confirmation-dialog')

  return (
    <Dialog open={open} onClose={onReject} fullWidth maxWidth={maxWidth} PaperProps={{ 'data-testid': testId }}>
      <LinearProgress loading={loading} />
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {typeof description === 'string' ? (
          <Typography sx={{ whiteSpace: 'pre-line' }} data-testid={`${testId}-description`}>
            {description}
          </Typography>
        ) : (
          description
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onReject} color={rejectColor} variant="text" data-testid={`${testId}-reject`} disabled={loading}>
          {rejectLabel}
        </Button>
        <Button onClick={onConfirm} color={confirmColor} variant="contained" data-testid={`${testId}-confirm`} loading={loading} autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
