import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

export function FilterPopper({ children, title, open, onClose, anchorEl }) {
  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom-start" sx={{ zIndex: 1300 }}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper elevation={4} sx={{ p: 2, width: 400 }} data-testid="filter-paper">
          <Stack spacing={1}>
            {title && <Typography variant="h5">{title}</Typography>}
            {children}
          </Stack>
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}
