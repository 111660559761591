import { useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router'

import CardActionArea from '@mui/material/CardActionArea'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import Typography from '@components/_mui/Typography'

import usePubNub, { Channels } from '@shared/hooks/src/usePubNub'
import useSound from '@shared/hooks/src/useSound'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { filterCache, handleErrorSilently, QK, UserRole } from '@shared/utils'

import Avatar from '@components/Avatar'

import { useGetPatient } from '../Appointments/Appointments.hooks'
import { styles, Title } from '../Notifications.utils'

/**
 * Handle the display of an On Demand Appointment notification.
 */
export default function OnDemandAppointments() {
  const currentPath = useLocation().pathname
  const isVisitPage = currentPath.startsWith('/app/visits')

  const me = useMe()
  const [patient, setPatient] = useState(undefined)
  const [message, setMessage] = useState(undefined)

  const handleClose = () => setPatient(undefined)

  const getPatient = useGetPatient()
  const playSound = useSound({ howlProps: { src: '/sounds/notification2.mp3' } })

  const handlePendingOnDemand = ({ patient_id: patientId, minutes }) => {
    invalidateOnDemands()

    getPatient
      .mutateAsync(patientId)
      .then((patient) => {
        setMessage(getMessage(minutes))
        setPatient(patient)
        playSound()
      })
      .catch(handleErrorSilently)
  }

  usePubNub(
    'platform_admins',
    ({ action, attributes }) => {
      const data = JSON.parse(attributes)

      if (Channels.OnDemandPending === action) handlePendingOnDemand(data)
      if (Channels.OnDemandStatusUpdate === action) {
        filterOnDemands(data.on_demand_id)
        invalidatedAppointments()
      }
    },
    {
      enabled: !isVisitPage && me.role === UserRole.Admin,
    }
  )

  usePubNub(
    `user_${me.id}`,
    ({ action, attributes }) => {
      const data = JSON.parse(attributes)

      if (Channels.OnDemandPending === action) handlePendingOnDemand(data)
    },
    {
      enabled: !isVisitPage && me.role === UserRole.Provider,
    }
  )

  usePubNub('on_demand_appointments', async ({ action, attributes }) => {
    const { on_demand_id } = JSON.parse(attributes)

    if (Channels.OnDemandPending === action) return invalidateOnDemands()
    if ([Channels.OnDemandAbandoned, Channels.OnDemandRejected].includes(action)) return filterOnDemands(on_demand_id)
    if (Channels.OnDemandAccepted === action) {
      if (me.role === UserRole.Admin) {
        invalidateOnDemand(on_demand_id)
        invalidateOnDemands()
        return
      }
      return filterOnDemands(on_demand_id)
    }
  })

  return (
    <Snackbar
      open={Boolean(patient)}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <CardActionArea component={RouterLink} to="/app/appointments?tab=on-demand" target="_blank" sx={[styles.container, styles.warning]}>
        <Title label="On Demand Appointments" onClose={handleClose} />
        <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
          <Avatar user={patient} size="sm" />
          <Typography>
            <b>{patient?.fullName}</b> {message}
          </Typography>
        </Stack>
      </CardActionArea>
    </Snackbar>
  )
}

const invalidateOnDemand = (id) => queryClient.invalidateQueries({ queryKey: QK.appointments.onDemand.id(id).details })
const invalidateOnDemands = () => queryClient.invalidateQueries({ queryKey: QK.appointments.onDemand.lists })
const invalidatedAppointments = () => queryClient.invalidateQueries({ queryKey: QK.appointments.lists })
const filterOnDemands = (id) =>
  queryClient.setQueriesData(
    { queryKey: QK.appointments.onDemand.lists },
    filterCache((onDemandLog) => onDemandLog.id !== id)
  )

const getMessage = (minutes) => {
  if (!minutes) return 'is waiting to connect for an on demand appointment.'

  switch (minutes) {
    case 0:
      return 'has just requested an on demand appointment.'
    case 3:
      return 'has been waiting for an on demand appointment for 3 minutes.'
    case 6:
      return 'has been waiting for an on demand appointment for 6 minutes. Please respond soon.'
    case 9:
      return 'has been waiting for an on demand appointment for 9 minutes. This is the final reminder — the request will be canceled in 3 minutes.'
    default:
      return `has been waiting for an on demand appointment for ${minutes} minutes.`
  }
}
