import { useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'

import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@components/_mui/Typography'

import useLoadingState from '@shared/hooks/src/useLoadingState'
import useQuery from '@shared/hooks/src/useQuery'
import API from '@shared/services/src/API'
import { getTestId, QK } from '@shared/utils'

import { MedicalServiceIcon } from '@icons'
import Avatar from '@components/Avatar'

/** Wrapper around MUI Autocomplete to find and select cbo */
export default function SelectCBO({
  value: cbo,
  onChange,
  label,
  error,
  helperText,
  placeholder = 'Search',
  loading: outsideLoading = false,
  inputProps = {},
  queryParams = {},
  ...rest
}) {
  const testId = getTestId(rest, 'cbo-selector')

  const [interacted, setInteracted] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const { data, isPending, isRefreshing } = useQuery({
    queryKey: QK.cbos.list(queryParams),
    queryFn: () => API.cbos.list(queryParams),
    staleTime: 60 * 1000,
    placeholderData: keepPreviousData,
    enabled: interacted,
    select: (data = []) => {
      return data.filter((cbo) => cbo.name.toLowerCase().includes(inputValue.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name))
    },
  })

  const loading = useLoadingState((interacted && (isPending || isRefreshing)) || outsideLoading)

  return (
    <Autocomplete
      autoComplete
      selectOnFocus
      disableCloseOnSelect={rest.multiple}
      onFocus={() => {
        if (!interacted) setInteracted(true)
      }}
      forcePopupIcon={false}
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      value={cbo}
      options={data || []}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onChange={(event, cbo) => onChange(cbo)}
      onInputChange={(event, search) => {
        if (!event || event.type === 'click') return setInputValue('')
        setInputValue(search)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          error={error}
          placeholder={placeholder}
          slotProps={{
            input: {
              ...params.InputProps,
              ...(!rest.multiple && {
                startAdornment: cbo ? (
                  <Avatar.CBO size="xs" data={cbo} />
                ) : (
                  <Avatar.Icon size="xs" variant="rounded">
                    <MedicalServiceIcon style={{ fontSize: 16 }} />
                  </Avatar.Icon>
                ),
              }),
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
            htmlInput: { ...params.inputProps, 'data-testid': `${testId}-input` },
          }}
          {...inputProps}
        />
      )}
      renderOption={({ key, ...props }, cbo, { selected }) => (
        <li key={cbo.id} data-testid={`${testId}-${cbo.name}`} {...props}>
          {rest.multiple && <Checkbox sx={{ mr: 1 }} checked={selected} />}
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center', minWidth: 0 }}>
            <Avatar.CBO size="xs" data={cbo} />
            <Typography noWrap>{cbo.name}</Typography>
          </Stack>
        </li>
      )}
      {...rest}
    />
  )
}
