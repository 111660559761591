import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@components/_mui/Typography'

import { useQueryEvents } from '@shared/hooks/src/useQueryEvents'
import { getTestId, UserRole } from '@shared/utils'

import useProvider from '@hooks/useProvider'
import { CloseOutlinedIcon, SearchOutlinedIcon } from '@icons'
import SelectUser from '@components/SelectUser'

import { FilterPopper } from '../TableFilters.utils'

ProviderSelect.propTypes = {
  /** Label of the button */
  label: PropTypes.string.isRequired,

  /** Title of the popper */
  title: PropTypes.string,

  /** Callback fired when the value changes */
  onChange: PropTypes.func.isRequired,

  /** Dayjs value */
  value: PropTypes.number,
}

/**
 * Button opens a popper to select or manually search a provider
 *
 * @example
 * <ProviderSelect label="Assigned Provider" value={value} onChange={handleChange} />
 */
export default function ProviderSelect({ label, title, onChange, value, ...other }) {
  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [provider, setProvider] = useState(null)

  const testId = getTestId(other, `provider-select-${label}`)

  // Reset the provider if the initialValue changes to empty
  useEffect(() => {
    if (!value && provider) {
      setProvider(null)
    }
  }, [value, provider])

  const query = useProvider(value, { enabled: Boolean(value) && !provider })

  useQueryEvents(query, {
    onSuccess: (provider) => setProvider(provider),
  })

  const handleToggle = () => setOpen((o) => !o)

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return
    setOpen(false)
  }

  const handleSelect = (selectedProvider) => {
    onChange(selectedProvider?.providerId)
    setProvider(selectedProvider)
    setOpen(false)
  }

  return (
    <>
      <Button
        ref={anchorRef}
        color="inherit"
        endIcon={value ? <CloseOutlinedIcon /> : <SearchOutlinedIcon />}
        onClick={value ? () => onChange(undefined) : handleToggle}
        data-testid={`${testId}-button`}
        {...other}
      >
        {label}
        {(provider || query.isFetching) && (
          <Typography
            component="span"
            noWrap
            sx={{
              color: 'primary.main',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
          >
            {provider ? `: ${provider.fullName}` : <Skeleton width={80} sx={{ ml: 1 }} />}
          </Typography>
        )}
      </Button>
      <FilterPopper title={title} open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <SelectUser
          autoFocus
          openOnFocus
          disableStyles
          placeholder="Provider"
          role={UserRole.Provider}
          disabled={query.isFetching}
          loading={query.isFetching}
          value={provider}
          onChange={handleSelect}
          data-testid={testId}
        />
      </FilterPopper>
    </>
  )
}
