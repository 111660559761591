import SvgIcon from '@mui/material/SvgIcon'

export default function LocalPharmacyOutlined(props) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14 3.333h-1.76L13 1.24 11.433.667l-.973 2.666H2v1.334l1.333 4-1.333 4V14h12v-1.333l-1.333-4 1.333-4V3.333zm-1.407 9.334H3.407l1.333-4-1.333-4h9.186l-1.333 4 1.333 4zM8.667 6H7.333v2h-2v1.333h2v2h1.334v-2h2V8h-2V6z"
        fill="#949696"
      />
    </SvgIcon>
  )
}
